import axios from "axios";
import * as Cookies from "js-cookie";
import store from "@/store/index";
import Api from "./api";

const AUTH_TOKEN_KEY = "authToken";
const CURRENT_USER = "currentUser";

export function loginUser(userData) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Api().post("/auth/login/", userData);
      if (response.data.status === "success") {
        const payload = {
          token: response.data.data.access,
          userDetails: response.data.data.user_details,
          userPermissions: response.data.data.user_permissions,
        };
        setAuthToken(payload.token);
        this.$store.commit("auth/SET_AUTH_USER", payload);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function logoutUser() {
 
  sessionStorage.clear();
}

export function setAuthToken(token) {
  Cookies.set(AUTH_TOKEN_KEY, token);
}

export function getAuthToken() {
  return Cookies.get(AUTH_TOKEN_KEY);
}

export function clearAuthToken() {
  axios.defaults.headers.common.Authorization = "";
  return Cookies.remove(AUTH_TOKEN_KEY);
}

export function isLoggedIn() {
  return !!store.getters["auth/isAuthToken"];
}

export function setCurrentUser(data) {
  Cookies.set(CURRENT_USER, JSON.stringify(data));
}

export function getCurrentUser() {
  return JSON.parse(Cookies.get(CURRENT_USER));
}

export function clearCurrentUser() {
  return Cookies.remove(CURRENT_USER);
}

export function isSuperUser() {
  return isLoggedIn();
}
export function getUserRole() {
  if (isLoggedIn()) {
    return getCurrentUser().role;
  } else {
    return [];
  }
}

export function isTokenActive(date) {
  const now = Math.floor(Date.now() / 1000);
  return date > now;
}
