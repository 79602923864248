<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "ForeignEmployeeIcon"
})
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
    <g clip-path="url(#clip0_37459_154544)">
      <path d="M24.304 10.9537V2.85138C24.304 2.43548 24.1388 2.0366 23.8447 1.74251C23.5506 1.44842 23.1517 1.2832 22.7358 1.2832H2.34943C1.93352 1.2832 1.53465 1.44842 1.24056 1.74251C0.946469 2.0366 0.78125 2.43548 0.78125 2.85138V16.965C0.78125 17.3809 0.946469 17.7798 1.24056 18.0739C1.53465 18.368 1.93352 18.5332 2.34943 18.5332H8.09943" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5.625 6.51048C5.625 6.81938 5.68584 7.12526 5.80406 7.41065C5.92227 7.69604 6.09554 7.95535 6.31396 8.17378C6.53239 8.39221 6.79171 8.56548 7.0771 8.68369C7.36249 8.8019 7.66837 8.86275 7.97727 8.86275C8.28618 8.86275 8.59206 8.8019 8.87745 8.68369C9.16284 8.56548 9.42215 8.39221 9.64058 8.17378C9.85901 7.95535 10.0323 7.69604 10.1505 7.41065C10.2687 7.12526 10.3295 6.81938 10.3295 6.51048C10.3295 5.88661 10.0817 5.2883 9.64058 4.84717C9.19944 4.40603 8.60113 4.1582 7.97727 4.1582C7.35341 4.1582 6.7551 4.40603 6.31396 4.84717C5.87283 5.2883 5.625 5.88661 5.625 6.51048Z" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.52783 13.9924H3.85938C3.85925 13.178 4.10029 12.3814 4.5529 11.7003C5.0055 11.0191 5.64997 10.4831 6.40718 10.1581C7.16439 9.83303 8.00138 9.73309 8.81538 9.87053C9.62938 10.008 10.385 10.3768 10.9894 10.9317" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14.8984 5.20508H19.603" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.5938 14.6127C13.5938 15.8605 14.0894 17.0571 14.9717 17.9394C15.854 18.8216 17.0506 19.3173 18.2983 19.3173C19.546 19.3173 20.7426 18.8216 21.6249 17.9394C22.5072 17.0571 23.0028 15.8605 23.0028 14.6127C23.0028 13.365 22.5072 12.1684 21.6249 11.2861C20.7426 10.4039 19.546 9.9082 18.2983 9.9082C17.0506 9.9082 15.854 10.4039 14.9717 11.2861C14.0894 12.1684 13.5938 13.365 13.5938 14.6127Z" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M19.8395 13.0449L17.6567 15.9559C17.6121 16.0152 17.5564 16.0651 17.4925 16.1028C17.4287 16.1405 17.358 16.1653 17.2846 16.1757C17.2112 16.1861 17.1365 16.1819 17.0647 16.1634C16.9929 16.1449 16.9254 16.1125 16.8662 16.0679C16.8452 16.052 16.8253 16.0347 16.8068 16.016L15.6797 14.8889" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_37459_154544">
        <rect width="25.0909" height="23" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped>

</style>