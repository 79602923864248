import {RouteRecordRaw} from "vue-router";
import {requireAuth} from "@/router/routeServices";


const routes: Array<RouteRecordRaw> = [
    {
        path: "/your-time-off",
        name: "yourTimeOff",
        component: () =>
            import("@/components/dashboard/employees/ProfileTimeOff.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Your Space"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/your-project-hours",
        name: "yourProjectHours",
        component: () =>
            import("@/components/dashboard/employees/project-hours/ProjectHours.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Your Space"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/your-advance-payment",
        name: "yourAdvancePayment",
        component: () =>
            import("@/views/dashboard/employees/EmployeeAdvancePayments.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Your Space"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/your-entertainment-requests",
        name: "yourEntertainmentRequests",
        component: () =>
            import("@/views/dashboard/employees/EmployeeEntertainmentRequest.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Your Space"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/your-flight-requests",
        name: "yourFlightRequests",
        component: () =>
            import("@/views/dashboard/employees/EmployeeFlightRequests.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Your Space"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/your-internal-missions",
        name: "yourInternalMissions",
        component: () =>
            import("@/views/dashboard/employees/EmployeeInternalMission.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Your Space"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/view-advance-payments",
        name: "viewAdvancePayments",
        component: () =>
            import(
                "@/views/dashboard/accounting/advance-payments/EmployeeRequestDetails.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Your Space"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/profile-add-payment-request",
        name: "profileAddPaymentRequest",
        component: () =>
            import(
                "@/views/dashboard/accounting/advance-payments/AddPaymentRequest.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Your Space"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/profile-add-flight-request",
        name: "profileAddFlightRequest",
        component: () =>
            import(
                "@/views/dashboard/accounting/flight-requests/AddFlightPackage.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Your Space"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/view-internal-mission",
        name: "viewInternalMission",
        component: () =>
            import(
                "@/views/dashboard/hr-resources/internal-mission/EmployeeInternalMission.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Your Space"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/profile-add-internal-mission-plan",
        name: "profileAddInternalMissionPlan",
        component: () =>
            import(
                "@/views/dashboard/hr-resources/internal-mission/AddInternalMissionPlan.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Your Space"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/profile-add-entertainment-request",
        name: "profileAddEntertainmentRequest",
        component: () =>
            import("@/views/dashboard/hospitality/AddHospitalityPackage.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Your Space"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/view-entertainment-request-details",
        name: "entertainmentRequestDetails",
        component: () =>
            import("@/views/dashboard/hospitality/EmployeeRequestDetails.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Your Space"],
        },
        beforeEnter: requireAuth,
    },
];

export default routes;