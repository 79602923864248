import {RouteRecordRaw} from "vue-router";
import {requireAuth} from "@/router/routeServices";


const routes: Array<RouteRecordRaw> = [
    {
        path: "/all-jobs",
        name: "allJobs",
        component: () => import("../views/dashboard/jobbase/index.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Jobbase"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/add-jobbase",
        name: "addJobbase",
        component: () => import("../views/dashboard/jobbase/AddJobBase.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Jobbase"],
        },
        beforeEnter: requireAuth,
    },

    {
        path: "/jobbase",
        name: "viewJobDetails",
        component: () => import("../views/dashboard/jobbase/EditJobBase.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Jobbase"],
        },
        beforeEnter: requireAuth,
       redirect: {name: "jobDetails"},
        children: [

            {
                path: "view-job-details",
                name: "jobDetails",
                component: () => import("../views/dashboard/jobbase/JobDetails.vue"),
            
            },
            {
                path: "edit-job",
                name: "editJob",
                component: () => import("../components/dashboard/job-base/JobbaseForm.vue"),
            
            },
            {
                path: "preview-job",
                name: "previewJob",
                component: () => import("../views/dashboard/jobbase/PreviewJob.vue"),
            
            },
            {
                path: "all-applied-jobs",
                name: "applicationInformation",
                component: () => import("../components/dashboard/job-base/AppliedJobsList.vue"),
               
            },
            {
                path: "applied-job-details",
                name: "appliedJobDetails",
                component: () => import("../views/dashboard/jobbase/ViewSingleAppliedJob.vue"),
               
            },
            {
                path: "edit-applied-job",
                name: "editAppliedJob",
                component: () => import("../views/dashboard/jobbase/EditAppliedJob.vue"),
               
            },
        ]
    },
   
   
];

export default routes