//login user endpoint
export const loginUser = "/auth/login/";
export const resetAuthUserPassword = "/auth/reset-password/";
export const changeAuthUserPassword = "/auth/change-password?token=";
export const refreshTokenEndpoint = "auth/token/refresh/";
export const getUserInformation = "auth/get-user-information/";
export const getUserProject = "/projects/project/get-user-projects/";
// termination and absence
export const addEmployeeInfo = "/hr/employees/add-personal-info/";
export const checkEmployeeEmail = "/hr/employees/check-email-availability/";
export const getEmployeeContactInfo =
  "/hr/employees/get-employee-personal-contact-info/";
export const employeeTermination = "/hr/employee/add-termination/";
export const employeeAbsence = "/hr/employee/add-employee-absence/";
export const getEmployeeAbsence = "/hr/employee/get-employee-absences/";
export const getAllEmployeeAbsent = "/hr/employee/get-all-absences/";
export const getAllEmployeeAbsentByTeamLeader =
  "/hr/employee/get-all-absences-by-team-leader/";
export const deleteAbsence = "/hr/employee/delete-absence/";
export const allEmployees = "/hr/employees/get-all-employees/";
export const searchEmployee = "/hr/employees/search-employees/";
export const deleteSingleEmployee = "/hr/employees/delete-employee/";
export const deleteMultipleEmployees =
  "/hr/employees/delete-multiple-employees/";

export const getCategoriesIcons =
  "/configuration/general/get-general/ICON Category/";
export const projectStatistics =
  "/projects/project/contract/project-statistics/";
export const getCountriesRegions = "/configuration/regions/get-all-regions/";

//employee signature

export const uploadEmployeeSignature = "/hr/user/upload-signature/";
export const getEmployeeSignature = "/hr/user/retrieve-signature/";

//announcements endpoint
export const getMyAnnouncements = "/hr/announcements/";
export const saveAnnouncementAsDraft = "/hr/announcements/";
export const saveAndSendAnnouncement = "/hr/announcements/send/";
export const updateAnnouncement = "/hr/announcements/modification/";
export const updateAndSendAnnouncement = "/hr/announcements/send/";
export const deleteAnnouncement = "/hr/announcements/modification/";
export const teamAnnouncement = "/configuration/general/get-general/Teams";
export const positionAnnouncement =
  "/configuration/general/get-general/Positions";

// project modules endpoints
export const addOrUpdateProject = "/projects/project/add-project/";
export const fetchAllProject = "/projects/project/all-projects/";
export const fetchSingleProject = "/projects/project/single-project/";
export const searchProject = "/projects/project/search-projects/";
export const deleteProject = "/projects/project/archive-project/";
export const deleteMultipleProjects = "/projects/project/archive-projects/";
export const addOrEditCostCenter = "/configuration/projects/add-cost-center/";
export const getSingleCostCenter = "/configuration/projects/get-cost-center/";
export const generateCostCenterCode =
  "/configuration/projects/generate-cost-center/";
export const filterCostcenter = "/configuration/projects/filter-cost-centers/";
export const SearchCostCenters = "/configuration/projects/search-cost-centers/";
// planning tool
export const getPlanningTool = "/projects/project/budget/get-planning-data/";
export const AddUpdateplanningTool =
  "/projects/project/budget/add-update-planning-data";
export const getPlanningToolReport = "/projects/project/planning/report/";
export const getPlanningToolReportData =
  "/projects/project/planning/report-data/";
// project configuarations endpoints
export const getAdmins =
  "/configuration/roles-configuration/filter-roles-configuration/";
export const updateRoles =
  "/configuration/roles-configuration/update-roles-configuration/";
// project  files
export const addProjectFile = "/projects/project/project-save-files/";
export const fetchSingleProjectFile = "/projects/project/files/";
export const fetchAllProjectFile = "";
export const manageProjectFile = "/projects/project/manage-files/";
export const deleteFile = "/projects/project/delete-upload/";
export const updateFile = "/projects/project/project-update-addenda/";
export const fetchProjectMangers = "/hr/employees/get-project-managers/";
export const getTerms = "/configuration/contract-terms-and-conditions/";
// project contract

// project contract
export const addOrUpdateContractDetails =
  "/projects/project/add-contract-details/";
export const fetchContractDetails =
  "/projects/project/contract/get-contract-details/";
export const addOrUpdatePartnerWPS =
  "/projects/project/contract/add-partner-with-profit-share/";
export const fetchAllPartnerWPS =
  "/projects/project/contract/get-all-partners-with-profit-share/";
export const deletePartnerWPS =
  "/projects/project/contract/delete-partner-with-profit-share/";
export const fetchSinglePartnerWPS =
  "/projects/project/contract/get-single-partner-with-profit-share/";
export const addOrUpdateBankGuarantee =
  "/projects/project/contract/add-bank-guarantee/";
export const fetchSingleBankGuarantee =
  "/projects/project/contract/get-single-bank-guarantee/";
export const addOrUpdateAdvancePayment =
  "/projects/project/contract/add-advance-payment/";
export const deleteAdvancePayment =
  "/projects/project/contract/delete-advance-payment/";
export const fetchSingleAdvancePayment =
  "/projects/project/contract/get-single-advance-payment/";
export const fetchAllAdvancePayment =
  "/projects/project/contract/get-all-advance-payments/";
export const addOrUpdateProjectNote =
  "/projects/project/contract/add-project-note/";
export const addOrUpdateInterimPayment =
  "/projects/project/contract/add-interim-payment/";
export const fetchInterimPayment =
  "/projects/project/contract/get-all-interim-payments/";
export const deleteInterimPayment =
  "/projects/project/contract/delete-interim-payment/";
export const toggleInterimPayment =
  "projects/project/contract/save-interim-payment-basic-view/";
export const requestAdvancePayment =
  "/accounting/outgoing-invoices/interim-payment/send-outgoing-invoice-request/";
export const getProjectHistory = "/projects/project/get-update-history/";
// project budget
export const addOrUpdateBudgetSection = "/projects/project/add-budget-section/";
export const deleteBudgetSection = "/projects/project/delete-budget-section/";
export const fetchAllBudgetSection =
  "/projects/project/get-project-budget-sections/";
export const fetchSingleBudgetSection =
  "/projects/project/get-single-budget-section/";
export const addOrUpdateBudgetItem = "/projects/project/add-budget-item/";
export const deleteBudgetItem = "/projects/project/delete-budget-item/";
export const fetchSingleBudgetItem = "/projects/project/get-budget-item/";
export const postBudgetSectionUrl = "/projects/project/budget-section/";
export const patchBudgetSectionUrl = "/projects/project/budget-section/";
export const deleteBudgetSectionUrl = "/projects/project/budget-section/";
export const updateBudgetSectionLineItemUrl =
  "/projects/project/project-budget-section/";
export const getAllBudgetSectionUrl =
  "/projects/project/project-budget-section/";
export const budgetBasicView = "/projects/project/budget-basic-view/";
export const budgetBasicVersionSave = "/projects/project/project-budget/save/";
export const getPostBudgetCalculation =
  "/projects/project/budget-post-calculation/";
export const getBudgetBasicVersion =
  "/projects/project/project-budget/get-basic-version/";
export const reportStructure = "/reports/structure-of-budget/";
export const updateBudgetBasicVersion =
  "/projects/project/project-budget/update-basic-version/";

// project local budget
export const getAllProjectLocalBudgetUrl = "/projects/local_budget/";
export const putProjectLocalBudgetUrl = "/projects/local_budget/";

// Leaves management
export const employeeRequestLeave = "/hr/employee/request-leave/";
export const getSingleLeave = "/hr/employee/get-leave/";
export const getEmployeeLeave = "/hr/employee/get-employee-leaves/";
export const getUnpaginatedData = "/hr/employee/unpaginated-employee-leaves/";
export const getEmployeeLeaveByHrOrApprover = "/hr/hr/get-employee-leaves/";
export const approverLeaveApproval = "/hr/approver/leaves-approval/";
export const hrLeaveApproval = "/hr/hr/leaves-approval/";
export const hrLeaveRequest = "/hr/hr/create-employee-leave/";
export const getAllPendingLeaves = "/hr/hr/get-pending-employee-leaves/";
export const increaseOrDecreaseLeave = "/hr/set-employee-leave-approver/";
export const getDefaultApprover = "/hr/get-user-leave-approver/";
export const editLeave = "/hr/employee/update-leave-request/";
export const filterLeaves = "/hr/hr/filter-all-leaves/";
export const getRecipientAnnouncements =
  "/hr/announcements/recipient-announcements/";
export const viewRecipientAnnouncements =
  "/hr/announcements/view-announcement/";
export const publicHolidays =
  "/configuration/public-holiday/get-all-public-holidays/";
export const searchPublicHolidays =
  "/configuration/public-holiday/search-public-holiday/";

//project configurations contract tasks

export const postContractTaskUrl = "/configuration/projects/add-contract-task/";
export const getAllContractTaskUrl = "/configuration/projects/get-tasks/";
export const putContractTaskUrl =
  "/configuration/projects/manage-contract-task/";
export const getOneContractTaskUrl =
  "/configuration/projects/manage-contract-task/";
export const deleteContractTaskUrl =
  "/configuration/projects/manage-contract-task/";

export const searchContractTaskUrl =
  "/configuration/projects/search-contract-tasks/";

// countries
export const getAllCountriesNonPaginated =
  "/configuration/countries/get-all-countries/";

// User Documents
export const getUserDocumentsUrl = "/hr/expert/documents/get-documents/";
export const manageUserDocumentsUrl = "/hr/expert/documents/manage-documents/";

//User Qualifications
export const getAllExpertQualifications =
  "/hr/experts/qualifications/get-all-expert-qualifications/";
export const addExpertQualification =
  "/hr/experts/qualifications/add-qualification/";
export const updateExpertQualification =
  "/hr/experts/qualifications/update-qualification/";
export const deleteExpertQualification =
  "/hr/experts/qualifications/delete-qualification/";

//project configurations contract approvers
export const getAllEmployeesUrl = "/configuration/projects/list-hr-employees";

export const getAllContractApproversUrl =
  "/configuration/projects/get-contract-approvers/";

export const postContractApproversUrl =
  "/configuration/projects/add-contract-approvers/";

// Contract modules endpoint
export const getAllProjectContract =
  "/projects/project/employee-contracts/get-contracts/";
export const getAllProjectContracts =
  "/projects/project/employee-contracts/manage-contract/";
export const deleteContractEndpoint =
  "/projects/project/employee-contracts/manage-contract/";
export const getContractBytype =
  "/projects/project/employee-contracts/get-contracts-by-type/";

// contract addenda
export const getContractAddendumInfo =
  "projects/project/employee-contracts/get-single-addendum/";
export const getAddendumDeliverables =
  "projects/project/employee-contracts/get-addendum-deliverables/";

//contract travel
export const addNewTravelItem =
  "/projects/project/employee-contracts/add-travel/";
export const updateTravel =
  "/projects/project/employee-contracts/manage-travel/";
export const getSingleTravel =
  "/projects/project/employee-contracts/manage-travel/";
export const deleteTravelItem =
  "/projects/project/employee-contracts/manage-travel/";
export const getContractTravels =
  "/projects/project/employee-contracts/get-contract-travels/";

// freelancer Deliverables
export const addDeliverablesInformation =
  "/projects/project/employee-contracts/add-deliverable-information/";
export const deleteDeliverable =
  "/projects/project/employee-contracts/delete-project-deliverable/";
export const getSingleDeliverable =
  "/projects/project/employee-contracts/get-project-deliverable/";
export const addOrUpdateProjectDeliverable =
  "/projects/project/employee-contracts/add-update-project-deliverable/";
export const getDeliverablePerContract =
  "/projects/project/employee-contracts/get-project-deliverables/";

// basic information
export const addBasicInformation =
  "/projects/project/employee-contracts/add-contract-basic-info";

// Project funds
export const addProjectFund =
  "/projects/project/employee-contracts/add-update-project-fund/";
export const getProjectFund =
  "/projects/project/employee-contracts/get-project-funds/";
export const deleteProjectFund =
  "/projects/project/employee-contracts/delete-project-fund/";
export const updateFunds =
  "/projects/project/employee-contracts/get-project-funds/employment/";

// link budget item
export const linkBudgetItem =
  "projects/project/employee-contracts/link-to-budget-item/";

// project other allowance
export const addOrUpdateOtherAllowance =
  "/projects/project/employee-contracts/add-update-other-allowance/";
export const getOtherAllowance =
  "/projects/project/employee-contracts/get-contract-allowances/";
export const getOtherAllowanceForSingleContract =
  "/projects/project/employee-contracts/get-contract-allowances/";
export const getSingleAllowance =
  "/projects/project/employee-contracts/get-contract-allowance/";
export const deleteOtherAllowance =
  "/projects/project/employee-contracts/delete-other-allowance/";

export const getLinkedBudgetItem =
  "/projects/project/employee-contracts/get_linked_budget_items/";
export const getTravelCountry =
  "/projects/project/employee-contracts/get-travels-unpaginated/";
export const getsingleLinkedItem =
  "/projects/project/employee-contracts/get-travel-links-data/";
export const getTravelCostTrue =
  "/projects/project/employee-contracts/update-travel-cost-per-country/";

// send for approval
export const sendForApproval =
  "/projects/project/employee-contracts/send-contract-approval/";

//delete basic information file
export const deleteBasicInformationFile =
  "/projects/project/employee-contracts/delete-contract-file/";
// get addenda
export const getAddenda = "/projects/project/employee-contracts/get-addenda/";
export const sendAddenda = "/projects/contract/create-addendum/";
export const deliverableAddenda =
  "/projects/project/employee-contracts/add-deliverable-for-addendum/";
export const travelAddenda =
  "/projects/project/employee-contracts/add-travel-for-addendum/";
export const getTravelAddenda =
  "projects/project/employee-contracts/get-addendum-travels/";
export const approveAddenda =
  "/projects/project/employee-contracts/send-addendum-for-approval/";
export const ceoSignsture =
  "/projects/project/employee-contracts/ceo-signature/";
export const previewApproval =
  "/projects/project/employee-contracts/contract-approval/";
export const saveContractDocument =
  "/projects/project/employee-contracts/save-contract-document/";
export const getDocument =
  "/projects/project/employee-contracts/save-contract-document/";
export const sendExpertMail =
  "/projects/project/employee-contracts/send-email-to-expert/";
export const pmSign =
  "/projects/project/employee-contracts/project-manager-signature/";
export const expertSign =
  "/projects/project/employee-contracts/expert-signature/";
export const unrestrictedContract =
  "/projects/project/employee-contracts/get-single-contract-unrestricted/";
//mission endpoints
export const createMissionPlan = "/projects/project/missions/";
export const getMissionsPaginated = "/projects/project/missions/";
export const getMissionReport =
  "/projects/project/missions/report-acceptance/get-all-reports/";
export const getSingleMission = "/projects/project/mission/";
export const updateMission = "/projects/project/mission/";
export const getDeploymentAgreement =
  "/projects/project/mission/deployment-agreement/";
export const searchMissionsEndpoint = "/projects/project/missions/search/";
export const getApprovedContracts =
  "/projects/project/employee-contracts/get-approved-contracts/";
//double taxation data
export const getAllDoubleTaxation =
  "/configuration/projects/double-taxation-status/";
export const addDoubleTaxation =
  "/configuration/projects/bulk-update-taxation-status/";
export const getSingleDoubleTaxation =
  "/configuration/projects/get-single-double-taxation-status/";

export const getAllApprovedContracts =
  "/projects/project/employee-contracts/get-approved-contracts/";

// project foreign establishment
export const getProjectForeignEstablishmentEndpoint =
  "/projects/project/foreign-establishment/";
export const getCountryWithForeignOffice =
  "/projects/foreign-office/get-country-foreign-offices/";
export const getAllSignedContractExpert =
  "/projects/project/employee-contracts/get-all-contracts/";
export const createForeignEstablishment =
  "/projects/project/foreign-establishment/";
export const updateForeignEstablishment =
  "/projects/project/foreign-establishment/establishment/";
export const approveEstablishment =
  "/projects/project/foreign-establishment/approve-request/";
export const viewSingleEstablishment =
  "/projects/project/foreign-establishment/view/";

export const establishmentOverview =
  "/projects/project/foreign-establishment/get-foreign-establishment-overview/";

// foreign offices endpoints
export const createForeignOffice =
  "/projects/foreign-office/add-foreign-office/";
export const UdateForeignOffice =
  "/projects/foreign-office/edit-foreign-office/";
export const getAllForeignOffice =
  "/projects/foreign-office/get-all-foreign-offices/";
export const getSingleForeignOffice =
  "/projects/foreign-office/get-single-foreign-office/";
export const deleteForeignOffice =
  "/projects/foreign-office/delete-foreign-office/";
export const createOfficeStaff = "/projects/foreign-office/add-office-staff/";
export const deleteOfficeStaff =
  "/projects/foreign-office/delete-office-staff/";
export const getAllOfficeStaff =
  "/projects/foreign-office/get-all-office-staff/";
export const getProjectForeignOfficesEndpoint = "/projects/project/offices/";
export const updateForeignOffice =
  "/projects/foreign-office/edit-foreign-office/";

export const searchForeignOffice =
  "/projects/foreign-office/search-foreign-offices/";
export const searchForeignEmployees =
  "/projects/foreign-office/search-foreign-employees/";
export const filterForeignEmployees =
  "/projects/foreign-office/filter-foreign-employees/";

export const updateOfficeStaff =
  "/projects/foreign-office/update-office-staff/";

export const getAllForeignEmployees =
  "/projects/foreign-office/get-all-foreign-employees/";
export const getAllBanks = "/configuration/projects/all-banks-unpaginated/";
export const getAllEmployees =
  "/projects/foreign-office/foreign-staff-dropdown/";

// PM Local endpoints
export const getAllPmlocals = "/projects/pm-local/all-projects/";
export const getSinglePmlocal =
  "/projects/project/pm-local/add-or-get-single-project/";
export const updatePmlocal =
  "/projects/project/pm-local/add-or-get-single-project/";
export const searchPmlocals = "/projects/pm-local/projects/search/";
export const getAllCurrencies = "/projects/project/pm-local/currencies/";
export const addCurrency = "/projects/project/pm-local/currencies/";
export const getAllClientRate = "/projects/project/pm-local/client-rate/";
export const updateClientRate = "/projects/project/pm-local/client-rate/";
export const getSingleClientRate =
  "/projects/project/pm-local/manage-client-rate/";
export const getAllBudgetDefinition =
  "/projects/project/pm-local/budget-definitions/";
export const updateBudgetDefinition =
  "/projects/project/pm-local/budget-definitions/";
export const getSingleCurrency = "/projects/pm-local/manage-currency/";
export const updateSingleCurrency = "/projects/pm-local/manage-currency/";
export const deleteSingleCurrency = "/projects/pm-local/manage-currency/";
export const currencyExchange = "/accounting/currency-exchange/";
export const deleteSingleClientRate =
  "/projects/project/pm-local/manage-client-rate/";
export const getAllExpertsNonPaginated =
  "/projects/project/employee-contracts/get-all-experts/Expert/";
export const getAllLanguagesNonPaginated =
  "/configuration/general/get-general/Languages/";
export const getAllRequestedFundUrl =
  "/projects/pm_local/foreign-establishment-funds/";
export const getOneRequestedFundUrl = "/projects/pm_local/project-fund/";
export const getRequestedFundStatsUrl =
  "/projects/pm_local/foreign-establishment-funds-stats/";
export const postRequestedFundsUrl =
  "/projects/pm_local/foreign-establishment-funds/";
export const getOneRequestedFundForApprovalUrl =
  "/projects/pm_local/project-fund-model-data/";
export const postOneRequestedFundForApprovalUrl =
  "/projects/pm_local/project-fund-approval/";

export const uploadFundPdf = "/projects/pm_local/upload-project-fund-pdf/";
export const generatePdf = "accounting/invoices/request-invoice-files/";
export const sendFundReminder =
  "/projects/pm_local/send-project-fund-approval-reminder/";

export const viewAllBudget = "/projects/project/project-budget-items/";
// Project Cheques

export const getAllCheques = "/projects/cash-tool/get-all-cheques/";
export const getSingleCheque = "/projects/cash-tool/get-single-cheques/";
export const addCheques = "/projects/cash-tool/add-cheque/";
export const currencyBudgetPosition =
  "/projects/cash-tool/currency-budget-dropdown/";
export const deleteProjectCheque = "/projects/cash-tool/delete-cheque/";
export const deleteAllProjectCheques =
  "/projects/cash-tool/delete-all-cheques/";
export const payInCheque = "/projects/cash-tool/pay-in-cheque/";
export const uploadChequeFile = "/projects/cash-tool/upload-cheque-file/";
export const updateCheque = "/projects/cash-tool/update-cheque/";

//bank/cash transfer
export const getBankCah = "/projects/pm-local/bank-cash-transfers/";
export const addBankCah = "/projects/pm-local/bank-cash-transfers/add/";
export const manageBankCah = "/projects/pm-local/bank-cash-transfers/manage/";
// export const editBankCah="/projects/pm-local/bank-cash-transfers/manage/"

// currency transaction
export const getCurrencyTransaction =
  "/projects/pm-local/currency-transactions/";
export const AddExchange = "/projects/pm-local/currency-transactions/add/";
export const manageExchange =
  "/projects/pm-local/currency-transactions/manage/";
export const currencyMonthRate =
  "/projects/pm-local/currency-transactions/get-rate-months/";
export const getSingleExchange =
  "/projects/pm-local/currency-transactions/get-rate-data/";
export const exchangeFile =
  "/projects/pm-local/currency-transactions/upload-file/";

// Project Expenses

export const getAllExpenses = "/projects/cash-tool/get-all-expenses/";
export const getSingleExpense = "/projects/cash-tool/get-single-expense/";
export const addExpense = "/projects/cash-tool/add-expense/";
export const deleteExpense = "/projects/cash-tool/delete-expense/";
export const uploadExpenseFile = "/projects/cash-tool/upload-expense-file/";
export const updateExpense = "/projects/cash-tool/update-expense/";

// pmlocal Project overview
export const getPmLocalOverview = "/projects/cash-tool/total-budget/";
export const getYearlyOverview = "/projects/cash-tool/yearly-budget/";
export const getMonthlyOverview = "/projects/cash-tool/monthly-budget/";
export const lockPmLocalMonth = "/projects/cash-tool/lock-month/";
export const unlockPmLocalMonth = "/projects/cash-tool/unlock-month/";

// flight request
export const getAllFlights =
  "/accounting/flight-request/get-all-flight-requests/";
export const addFlightRequest =
  "/accounting/flight-request/add-flight-request/";
export const getSingleFlightRequest =
  "/accounting/flight-request/get-flight-request/";
export const updateFlightRequest =
  "/accounting/flight-request/update-flight-request/";
export const deleteFlightRequest =
  "/accounting/flight-request/delete-flight-request/";
export const getCompaniesnonAcquicompanies =
  "/configuration/general/get-non-acquisition-companies/";
export const costCentersEndpoint =
  "/accounting/flight-request/get-cost-centres/";
export const getFlightDropdowns =
  "/accounting/flight-request/get-flight-request-dropdowns/";
export const getExpertContracts =
  "/accounting/flight-request/get-expert-contracts/";
export const payingCompany = "configuration/general/get-general/Companies/";
export const searchFlight =
  "/accounting/flight-request/search-flight-requests/";
export const getFirmExperts = "/accounting/flight-request/get-firm-experts/";
export const sendEmail = "/accounting/flight-request/send-email-to-agency/";
export const getOpenInvoices = "/accounting/get-open-invoices/";
export const addFlightSettlement =
  "/accounting/flight-request/add-flight-settlement/";
export const getEmployeeFlightRequests =
  "/accounting/flight-request/get-user-flight-requests/";
export const getFlightRequesDetails =
  "/accounting/flight-request/get-flight-signing-details/";
export const signFlightRequest =
  "/accounting/flight-request/sign-flight-request/";
export const allowanceInvoice =
  "/accounting/invoices/get-single-invoice-settlement/";
export const allowanceInvoiceGenerate =
  "/accounting/invoices/generate-single-invoice-settlement/";
export const allowanceInvoiceSettlement =
  "/accounting/invoices/get-settlement-invoices/";
export const planedAllowance =
  "/accounting/invoices/get-planning-allowances-data/";
export const getExpertTicket =
  "/projects/project/mission/settlement/flight-tickets/";
// hospitality package endpoints

export const getAllHospitalityPackage =
  "/hr/hospitality/list-hospitality-requests/";
export const getSingleHospitalityPackage =
  "/hr/hospitality/manage-hospitality-request/";
export const createHospitalityPackage =
  "/hr/hospitality/add-hospitality-request/";
export const updateHospitalityPackage =
  "/hr/hospitality/manage-hospitality-request/";
export const deleteHospitalityPackage =
  "/hr/hospitality/manage-hospitality-request/";
export const searchHospitalityPackages =
  "/hr/hospitality/search-hospitality-requests/";
export const settleHospitalityPackages =
  "/hr/hospitality/settle-hospitality-request/";
export const getBudgetItems =
  "/hr/hospitality/get-cost-center-related-budget-items/";

export const signatureRequest = "/hr/hospitality/request-signature/";

// advancement payment request endpoints
export const getAllAdvancementPaymentRequest =
  "/accounting/advance-payment-request/list-advance-payment-requests/";
export const getSingleAdvancementPaymentRequest =
  "/accounting/advance-payment-request/manage-advance-payment-request/";
export const createAdvancementPaymentRequest =
  "/accounting/advance-payment-request/add-advance-payment-request/";
export const updateAdvancementPaymentRequest =
  "/accounting/advance-payment-request/manage-advance-payment-request/";
export const deleteAdvancementPaymentRequest =
  "/accounting/advance-payment-request/manage-advance-payment-request/";
export const searchAdvancementPaymentRequest =
  "/accounting/advance-payment-request/search-advance-payment-requests/";
export const getAllEmployeesNonPaginated =
  "/projects/project/employee-contracts/get-all-experts/";
export const getAllExpertOrCompanyContract =
  "/accounting/advance-payment-request/get-expert-assigned-contracts/";
export const uploadResquestFile =
  "/configuration/file-uploads/request-file-uploads/";
export const uploadRequestedFile = "";
export const advancePaymentSignatureRequest =
  "/accounting/advance-payment-request/request-expert-signature/";

export const toggleVersion =
  "/projects/project/contract/save-advance-payment-basic-view/";
// export deleteFile=

export const settleAdvancementPaymentRequest =
  "/accounting/advance-payment-request/advance-payment-request-settlement/";

//Incoming Invoice endpoints
export const getIcomingInvoices = "/accounting/invoices/icoming-invoices/";
export const deletTemporaryInvoices = "/accounting/invoices/icoming-invoices/";
export const addIncomingInvoice = "/accounting/invoices/icoming-invoices/";
export const allInvoices = "/accounting/invoices/";
export const addInvoices = "/accounting/invoices/";
export const invoiceMarkedAsSeen =
  "/accounting/invoices/mark-new-invoice-as-seen/";
export const getAllInvoices = "/accounting/invoices/";
export const uploadInvoiceFile = "/accounting/invoices/upload_file/";
export const deleteInvoiceFile = "/accounting/invoices/delete_file/";
export const addExchangeRate = "/accounting/invoice/exchange-rate/";
export const addExchangeRateOnOutgoingInvoice =
  "/accounting/outgoing-invoices/exchange-rate/";
// /configuration/delete-request-file/

// report acceptance mission
export const updateReportAcceptance =
  "/projects/project/employee-contracts/update-deliverables/";
export const deleteReportAcceptanceFile =
  "/projects/project/missions/report-acceptance/delete-files/";

//Internal Mission plan endpoints
export const getInternalMissionPlan =
  "/hr/internal-missions/list-internal-missions/";
export const getSingleInternalMissionPlan =
  "/hr/internal-missions/manage-internal-mission/";
export const createInternalMissionPlan =
  "/hr/internal-missions/add-internal-mission/";
export const updateInternalMissionPlan =
  "hr/internal-missions/manage-internal-mission/";
export const deleteInternalMissionPlan =
  "/hr/internal-missions/manage-internal-mission/";
export const searchInternalMissionPlan =
  "/hr/internal-missions/search-internal-missions/";
export const settleInternalMissionPlan =
  "/hr/internal-missions/settle-internal-mission/";
export const internalMissionPlanSignatureRequest =
  "/hr/internal-missions/request-signature/";
export const getInternalMissionEntertainmentRequest =
  "/hr/internal-missions/manage-entertainment-request/";
export const updateInternalMissionEntertainmentRequest =
  "/hr/internal-missions/manage-entertainment-request/";
export const getSingleInstanceData =
  "/hr/internal-missions/get-single-instance-data/travel-and-utilities/";
export const deleteSingleInstanceData =
  "/hr/internal-missions/delete-single-instance/milleage-allowance/";
export const getEmployeeAdvancePayment =
  "/hr/internal-missions/get-employee-advance-payments/";
export const manageInternalMissionTimesheet =
  "/hr/internal-missions/internal-mission-timesheet/";

//mission settlement & invoice endpoints
export const createExpertMissionInvoice =
  "/accounting/invoices/expert-create-mission-invoice/";
export const getInvoiceDetailsForSettlement =
  "/accounting/invoices/generate-mission-planning-invoice/";
export const getMissionPlanningInvoiceSettlement =
  "/accounting/invoices/get-mission-planning-invoice-settlement/";
export const getMainMissionInvoices =
  "/accounting/invoices/get-main-mission-invoices/";
export const getExpertMissionPlanningInvoices =
  "/accounting/invoices/get-expert-mission-planning-invoices/";

export const getMainMissionPlanningSigningDetails =
  "/accounting/invoices/get-main-mission-planning-signing-details/";

export const updateMissionPlanningInvoiceSettlement =
  "/accounting/invoices/update-mission-planning-invoice-settlement/";
export const signMissionPlanningInvoice =
  "/accounting/invoices/sign-mission-planning-invoice/";
export const updateEmploymentSettlement =
  "/projects/project/mission/settlement/update-invoice/";
export const createEmploymentMission =
  "/projects/project/mission/settlement/create-invoice/";
// sanctioned expenses endpoints
export const getAllSanctionedExpenses =
  "/projects/sanctioned-expenses/get-all-sanctioned-expenses/";
export const searchSanctionedExpenses =
  "/projects/sanctioned-expenses/search-all-sanctioned-expenses/";
export const addSanctionedExpense =
  "/projects/sanctioned-expenses/add-sanctioned-expense/";
export const getAllFirmsOrExperts =
  "/projects/sanctioned-expenses/get-firms-or-experts-for-sanctioned-expense/";
export const getAllBudgetItems =
  "/projects/sanctioned-expenses/get-budget-items-for-sanctioned-expense/";
export const getSettlementForSanctionedExpense =
  "/projects/sanctioned-expenses/get-sanctioned-expense-settlement-details/";
export const getSanctionedExpenseSigningDetails =
  "/projects/sanctioned-expenses/get-sanctioned-expense-signing-details/";
export const getSingleSanctionedExpense =
  "/projects/sanctioned-expenses/get-single-sanctioned-expense/";
export const updateSanctionedExpenseSettlement =
  "/projects/sanctioned-expenses/update-sanctioned-expense-settlement/";
export const updateSingleSanctionedExpense =
  "/projects/sanctioned-expenses/update-single-sanctioned-expense/";
export const signSanctionedExpense =
  "/projects/sanctioned-expenses/sign-sanctioned-expense/";
export const deleteSanctionedExpense =
  "/projects/sanctioned-expenses/delete-single-sanctioned-expense/";
export const getAllCostCenters = "/configuration/projects/all-cost-centers/";
export const generateInvoice =
  "/projects/sanctioned-expenses/generate-invoice/";
export const updateBillingStatus =
  "/projects/sanctioned-expenses/update-billing-status/";
export const getAndUpdateComment =
  "/projects/sanctioned-expenses/update-comment/";

//Manual Inovices
export const getManualInvoices =
  "/accounting/manual-invoices/get-all-manual-invoices/";
export const searchManualInvoices =
  "/accounting/manual-invoices/search-all-manual-invoices/";
export const getBudgetItemsForManualInvoice =
  "/accounting/manual-invoices/get-budget-items-for-manual-invoice/";
export const addManualInvoice =
  "/accounting/manual-invoices/add-manual-invoice/";
export const getSingleManualInvoice =
  "accounting/manual-invoices/get-single-manual-invoice/";
export const getSettlementForManualInvoice =
  "/accounting/manual-invoices/get-manual-invoice-settlement-details/";
export const deleteManualInvoice =
  "/accounting/manual-invoices/delete-single-manual-invoice/";
export const updateManualInvoiceSettlement =
  "/accounting/manual-invoices/update-manual-invoice-settlement/";
export const getManualInvoiceSigningDetails =
  "/accounting/manual-invoices/get-manual-invoice-signing-details/";
export const signManualInvoice =
  "/accounting/manual-invoices/sign-manual-invoice/";

//project configurations keywords

export const manageAllKeywords = "/projects/keywords/manage-keywords/";
export const manageSingleKeyword = "/projects/keywords/manage-keyword/";
export const filterKeywordsByCategoryId =
  "/projects/keywords/filter-keyword-by-category/";

export const searchKeywordUrl =
  "/configuration/projects/search-contract-tasks/";

//Outgoing Invoices
export const getCreateUpdateOutgoingInvoices = "/accounting/outgoing-invoices/";
export const strikeThroughInvoices =
  "/accounting/outgoing-invoices/strike-through/";
export const addTemporaryOutgoing =
  "/accounting/outgoing-invoices/incoming-requests/manage-temporary-outgoing-invoice/";
export const getTemporaryOutgoing =
  "/accounting/outgoing-invoices/incoming-requests/get-all-temporary-outgoing-invoices/";
export const getSingleTemporaryOutgoing =
  "/accounting/outgoing-invoices/incoming-requests/manage-temporary-outgoing-invoice/";
export const removeOutgoingInvoicesRequest =
  "/accounting/outgoing-invoices/incoming-requests/manage-temporary-outgoing-invoice/";

//interim payments
export const markOutgoingInvoiceAsSeen =
  "/accounting/outgoing-invoices/mark-seen/";
export const outgoingComment = "/accounting/outgoing-invoices/comment/";
export const addOrDeleteComment =
  "/accounting/outgoing-invoices/interim-payment/";
export const getProjectInterimPayments =
  "/accounting/outgoing-invoices/interim-payments/";
export const getSingleInterimPayment =
  "/accounting/outgoing-invoices/project-interim-payments/";
export const createOrUpdateInterimPayment =
  "/accounting/outgoing-invoices/project-interim-payments/";
export const cashOutFlow = "/accounting/outgoing-invoices/cash-outflows/";
export const singleCashOutFlow =
  "/accounting/outgoing-invoices/interim-payment-cash-outflows/";
export const interimBasicVersion =
  "/accounting/project-interim-payments/basic-version/";
export const purchaseCurrency = "/accounting/currency-purchase/";
export const consumeCurrency = "/accounting/currency-consumption/";

// Account numbers
export const getAccountNumbersPaginated = "/configuration/account-numbers/?";
export const createAccountNumber =
  "/configuration/account-numbers/add-account-number/";
export const manageAccountNumbers =
  "/configuration/account-numbers/manage-account-number/";
export const getAccountNumbersUnpaginated = "/configuration/account-numbers/";
export const getEntertainmentAccountNumbersUnpaginated =
  "/configuration/account-numbers/?account_type=Entertainment_Account";

// VAT rates
export const getVatRates = "/configuration/vat-rates/";
export const createVatRates = "/configuration/vat-rates/add-vat-rate/";
export const manageVatRates = "/configuration/vat-rates/manage-vat-rate/";
export const getVatRatesUnpaginated = "/configuration/vat-rates/";

// PROJECT HOURS ENDPOINTS
export const getAllProjectHours =
  "/projects/project-hours/get-all-project-hours/";
export const lockAndUnlockProjectHours =
  "/projects/project-hours/lock-unlock-project-hours/";
export const getProjectHoursDetails =
  "/projects/project-hours/get-user-project-hours-details/";
export const createProjectHour =
  "/projects/project-hours/create-update-project-hours/";

// Business Area endpoints
export const getBusinessAreas =
  "/configuration/business-areas/get-all-business-areas/";
export const createBusinessArea =
  "/configuration/business-areas/add-business-area/";
export const getBusinessArea =
  "/configuration/business-areas/get-business-area/";
export const updateBusinessArea =
  "/configuration/business-areas/update-business-area/";
export const deleteBusinessArea =
  "/configuration/business-areas/delete-business-area/";
export const getPaginatedBusinessAreas =
  "/configuration/business-areas/get-paginated-business-areas";
export const updateBusinessAreaStatus =
  "/configuration/business-areas/update-business-area";

// Accruals Endpoints
export const getAllAccruals = "/accounting/accruals/get-all-transactions/";
export const getAllAccrualsMirror = "/accounting/accruals-mirror/";
export const createAccruals = "/accounting/accruals/create-transaction/";
export const createAccrualsMirror =
  "/accounting/accruals-mirror/save-yield-and-consumption/";
export const manageAccruals = "/accounting/accruals/update-status/";
export const getSingleAccrual = "/accounting/accruals/get-single-transaction/";
export const costCenterRelatedData =
  "/accounting/accruals/get-cost-center-related-firms-and-users/";
export const manageReserves = "/accounting/accruals/save-reserve/";
export const updateEntry = "/accounting/accruals/update-entry/";

//Advance payment client
export const getClientAdvancePayment = "/accounting/advance-payment-client/";

//Project Hours Reporting
export const getCostCentreProjectHours =
  "/reports/project-hours/view-cost-centre-project-hours/";
export const viewEmployeeProjectHoursReport =
  "/reports/project-hours/view-employee-project-hours-report/";
export const searchProjectHoursReport =
  "/reports/project-hours/search-project-hours-report/";
export const getDailyProjectHours =
  "/projects/project-hours/view-project-hours-for-day/";

//Break Even Reporting
export const getBreakEvenReport = "/reports/break-even/view-break-even/";
export const searchBreakEvenReport = "/reports/break-even/search-break-even/";

//Annual Vacation Reporting
export const getAnnualVacationReport = "/reports/annual-vacation/";

// cost overview
export const costOverview = "/reports/cost-overview/";
//Employee Month Reporting
export const getEmployeeMonthReport =
  "/reports/employees/view-employee-reports/";
export const searchEmployeeMonthReport =
  "/reports/employees/search-employee-reports/";

// Bank Guarantee reporting endpoints
export const getBankGuaranteeReport =
  "/reports/bank-guarantee/view-bank-guarantees/";
export const deleteFileUploaded = "/configuration/delete-request-file/";
export const deletefileUploadedFromDB =
  "/configuration/file-uploads/delete-request-file/";
export const filterCostCenterCompanyAndBusiness =
  "/hr/filter-cost-centers-and-companies/";
export const allBankGuarantee =
  "/projects/project/contract/get-all-bank-guarantees/";
export const getBankAccountNumbers =
  "/projects/project/contract/bank-accounts/";
export const deleteBankGuarantee =
  "/projects/project/contract/delete-bank-guarantee/";

export const getAccCashBank = "/accounting/account-cash-bank/";
export const loanGuarantee = "/accounting/loan-guarantees/";

export const loanGuaranteeOverview = "/accounting/loan-guarantees/overview/";

export const accountBalanceOverviewEndpoint = "/accounting/balances/overview/";
export const saveAccountBalanceOverviewEndpoint =
  "/accounting/balances/update-account-balance/";
export const liquidityOverviewEndpoint = "/reports/liquidity-overview/";
//entities
export const getEntityByDataType = "entities/get-partners-and-clients/";

//Entities Endpoint
export const getAllEntitiesHome = "/entities/get-all-entities-paginated/";
export const addEntity = "/entities/add-entity/";
export const getEntitySubsidiaries = "/entities/get-all-entites/";
export const getSingleEntityEndpoint = "/entities/get-single-entity/";
export const discardEntityChanges = "/entities/discard-entity-changes/";
export const entityAdvancedSearch = "/entities/advanced-search-entities/";
export const getAllEntitiesWithFilter =
  "/entities/get-all-entities-db/filter-by-fields/";

// Cover Pictures
export const getCoverPictures = "/projects/project/get-cover-pictures/";
export const addCoverPictures = "/projects/project/add-cover-pictures/";

//Daily packages endpoints
export const getAllTravellingExpenses =
  "/configuration/country-daily-packages/";
export const createTravellingExpenses =
  "/configuration/country-daily-packages/";

// mission invoice and settlement
export const getFeeIvoice = "/projects/project/mission/form/fee-invoice/";
export const travelExpenseInvoice =
  "/projects/project/mission/form/travel-invoice/";
export const otherAllowanceInvoice =
  "/projects/project/mission/form/other-allowance-invoice/";
export const reportInvoice = "/projects/project/mission/form/report-invoice/";
export const timesheetInvoice = "/projects/project/mission/form/get-timesheet/";
export const addTimeSheetFile =
  "/projects/project/mission/form/upload-timesheet/";
export const deleteTimeSheet =
  "/projects/project/mission/form/delete-single-timesheet/";
export const addTimeSheet = "/projects/project/mission/form/save-timesheet/";
export const sendSettlementMail =
  "/projects/project/mission/send-expert-email/";
export const lockMission = "/projects/project/mission/lock-mission/";
export const getTimeSheeetBudget =
  "/projects/project/mission/settlement/get-timesheet-budgets/";
export const updateTimeSheetBudget =
  "/projects/project/mission/settlement/update-timesheet-budgets/";
export const expertFlight = "/projects/project/missions/expert-flights/";
export const expertticket =
  "/projects/project/mission/settlement/flight-tickets/";
export const getGeneralSettlement =
  "/projects/project/mission/settlement/get-invoices/";
export const getMissionSingleInvoice =
  "/projects/project/mission/settlement/get-invoice-data";
// project references
export const getAllProjectReferences =
  "/projects/references/get-all-references/";
export const getSingleProjectReference = "/projects/references/get-reference/";
export const createProjectReference = "/projects/references/add-reference/";
export const updateProjectReference = "/projects/references/update-reference/";
export const projectReferenceApproval =
  "/projects/references/get-reference-change-log/";
export const getSelectedProjects =
  "/projects/references/get-selected-projects/";
export const getProjectByCostCenter =
  "/projects/references/get-cost-centre-projects/";

export const getUnPaginatedProjectsWithoutReference =
  "/projects/references/get-projects-unpaginated/";
export const getUnPaginatedProjectsWithReference =
  "/projects/references/get-projects-unpaginated/";

export const allClients = "/entities/get-partners-and-clients/";

export const bankGuanranteeAdditional =
  "/projects/project/contract/accounting-information/";

export const referenceAdvanceSearch = "/projects/references/advanced-search/";
export const getReferenceAdvanceSearchHistory =
  "/projects/references/get-search-history/";

//bank Account endpoints
export const bankAccountsEndpoint = "/accounting/bank-accounts/";
export const balancesEndpoint = "/accounting/balances/";
export const manageBalance = "/accounting/balances/manage-balance/";

//Maternity and Parental leave endpoint
export const maternityParentalLeave =
  "/hr/employee-leaves/get-all-maternity-leaves/";
export const addMaternityParentalLeave =
  "/hr/employee-leaves/add-maternity-leave/";
export const deleteMaternityParentalLeave =
  "/hr/employee-leaves/delete-maternity-leave/";
export const getSingleEmployeeMaternityParentalLeave =
  "/hr/employee-leaves/get-maternity-leave/";
export const updateMaternityParentalLeave =
  "/hr/employee-leaves/update-maternity-leave/";

//Expert and Company Overview Reporting
export const getAllExpertCompanyOverviewByCostCenter =
  "/reports/expert-and-company-overview/";

// Flight Management
export const getAllFlightOverview =
  "/accounting/flight-management/flight-requests-overview/";
export const approveFlightRequest =
  "/accounting/flight-request/approve-flight-request/";
export const getAllOpenFlights =
  "/accounting/flight-management/get-open-flights/";
export const sendFlightsForInvoicing =
  "/accounting/flight-management/send-flights-to-invoicing/";
export const getAllFlightSettlement =
  "/accounting/flight-management/flight-settlement/";
export const getSelectedFlightSettlement =
  "/accounting/flight-management/get-selected-flights/";

//project balance sheet endpoints
export const projectBalanceSheetEndpoint =
  "/projects/project-results-balance-sheet-overview/";
export const updateProjectBalanceSheet =
  "/projects/update-project-results-balance-sheet/";
export const uploadBalanceSheet =
  "/projects/upload-project-results-balance-sheet/";

//Evaluation Of Controllers endpoints
export const getEvaluationOfControllers = "/reports/evaluation-of-controllers/";

// Break Even Controlling
export const manageBreakEvenControlling = "/controlling/break-even/";
export const deleteBreakEvenRowItem =
  "/controlling/break-even/break-even-unit/";
export const liquidity = "/reports/liquidity-requirements/";
export const forecast = "reports/liquidity-forecast/";
export const viewBreakEvenControllingReport = "/reports/break-even-report/";

// Project Calculation Budget
export const getProjectCalculationBudget =
  "/projects/project/calculation/get-calculation-budget/";
export const manageProjectCalculationBudgetSection =
  "/projects/project/calculation/manage-calculation-budget-sections/";
export const manageProjectCalculationBudgetItems =
  "/projects/project/calculation/save-calculation-budget/";
export const deleteProjectCalculationBudgetItem =
  "/projects/project/calculation/manage-calculation-budget/";

// Other Invoices reporting
export const getAllOtherInvoices = "/reports/other-invoices-total/";
export const getAllCostCentersFilterByCompany =
  "/hr/filter-cost-centers-and-companies/";

export const interimReportCostCenter = "/reports/cost-centers-dropdown/";
export const cashoutflowReport = "/accounting/outgoing-invoices/cash-outflows/";

// cost performance overview
export const costPerformanceOverview = "/reports/cost-performance-overview/";
// export const structureOfRevenue = "/reports/structure-of-turnover/"
export const structureOfRevenue = "/reports/structure-of-revenue/";
export const stockValuation = "/reports/stock-valuation/";

export const openReceivablesEndpoint = "/reports/open-receivables/";
export const openInvoiceEndpoint = "/reports/invoices-report/";
export const getProjectYearsByCostCenter = "/reports/get-project-years/";
export const getCostCenterByProjects = "/reports/cost-centers-dropdown/";
export const getBudgetItemDetailsByCostItem = "/reports/get-budget-item-costs/";
export const getStructureOfBillignType = "/reports/structure-of-billing-type/";

// Donors endpoints
export const getAllDonors = "/configuration/donor/get-donors/";
export const createDonor = "/configuration/donor/add-donor/";
export const updateDonor = "/configuration/donor/update-donor/";
export const deleteDonor = "/configuration/donor/delete-donor/";
// Region endpoints
export const getAllRegions = "/configuration/regions/get-all-regions/";
export const createRegion = "/configuration/regions/create-region/";
export const updateRegion = "/configuration/regions/update-region/";
export const deleteRegion = "/configuration/regions/delete-region/";
export const validateProjectNumber = "/projects/project/project-numbers/";

// jobbase endpoints
export const getAllJobBase = "/configuration/jobs/get-jobs/";
export const createJobBase = "/configuration/jobs/add-job/";
export const manageJobBase = "/configuration/jobs/manage-job/";
export const getAllProjectsForJobbase =
  "/configuration/jobs/get-projects-dropdown/";

export const getAllJobApplication =
  "/configuration/job-applications/list-job-applications/";
export const updateJobApplication =
  "/configuration/job-applications/update-job-application/";
export const getSingleJobApplication =
  "/configuration/job-applications/get-job-application/";
export const deleteJobApplication =
  "/configuration/job-applications/delete-job-application/";

// CV template endpoints
export const getAllCvTemplates =
  "/configuration/cv-templates/get-cv-templates/";
export const createCvTemplate = "/configuration/cv-templates/add-cv-template/";
export const uploadCvTemplate =
  "/configuration/cv-templates/upload-cv-template/";
export const manageCvTemplate =
  "/configuration/cv-templates/manage-cv-template/";
export const getAllCvTemplatesGenerated =
  "/configuration/cv-templates/get-saved-titles_and_languages/";
export const deleteUploadedCvTemplate =
  "/configuration/cv-templates/upload-cv-template/";
export const manageBank = "/configuration/projects/add-bank/";
export const deleteBank = "/configuration/projects/delete-bank/";

// general configuration
export const getGeneralConfiguration = "/configuration/general/get-general/";
export const addGeneralConfiguration = "/configuration/general/add-general/";
export const updateGeneralConfiguration =
  "/configuration/general/update-general/";
export const deleteGeneralConfiguration =
  "/configuration/general/delete-general/";

// user management
export const getAllUsers = "/user-management/users/";
export const getUserDetails = "/user-management/users/get-user/";
export const sendWelcomeEmail = "/auth/send-employee-credentials-email/";
export const updateUserStatus = "/hr/employees/update-employee-status/";
export const sendExpertEmail = "/hr/employees/send-expert-activation-email/";

// reference templates
export const getAllReferenceTemplates =
  "/configuration/reference-templates/get-all-reference-templates/";
export const createReferenceTemplate =
  "/configuration/reference-templates/add-reference-template/";
export const manageReferenceTemplate =
  "/configuration/reference-templates/manage-reference-template/";
export const getReferenceTemplatesByLanguage =
  "/configuration/reference-templates/get-refrences-unpaginated/";
export const generateReferenceFile =
  "/projects/references/generate-reference-files/";

// Project Closure endpoints
export const getAllProjectClosures = "/projects/project/project-closure/";
export const endInvoicing = "/projects/project/end-invoicing/";
