<template>
  <aside
    :class="[
      'w-[311px] transition-all duration-700 ease-in-out bg-white border-r overflow-x-hidden overflow-y-auto shadow-lg rounded-lg',
      showMenu ? 'h-[609px]' : 'h-0',
    ]"
  >
    <!--    navLinks-->
    <div class="px-2">
      <div
        v-for="(
          { title, key, icon, moduleName, route }, index
        ) in defaultNavItems"
        :key="key"
      >
        <app-sidebar-nav-link-item
          v-if="isModuleAccessible(moduleName)"
          :icon="icon"
          :title="title"
          :sub-modules="checkSubModulePermissions(key, moduleName)"
          :ref="`${key}_nav_item`"
          @toggle-submodules="toggleSubmodules(key)"
          :link="route ? route : ''"
          :is-last="index === defaultNavItems.length - 1"
        ></app-sidebar-nav-link-item>
      </div>
    </div>
  </aside>
</template>

<script lang="js">
import {getTransString} from "@/services/helpers";
import {extractModuleNamesAsObject} from "@/services/permissions";
import {defineComponent} from "vue";
import AppSidebarNavLinkItem from "@/components/shared/widgets/AppSidebarNavLinkItem.vue";

export default defineComponent({
  name: "App Sidebar",
  components: { AppSidebarNavLinkItem },
  data() {
    return {
      logo: require("@/assets/logo.svg"),
      avatar: require("@/assets/Avatar.svg"),
      drawer: true,
      defaultNavItems: [
        { title: "Home", key: "home", icon: "custom:homeIcon", moduleName: "Home", type: "list-item", route: "/home" },
        { title: "Your Space", key: "yourSpace", icon: "custom:userIcon", moduleName: "Your Space", type: "group" },
        { title: "Employee Management", key: "employmentManagement", icon: "custom:EmployeeManagement", moduleName: "Employee Management", type: "group"},
        { title: "Time Management", key: "timeManagement", icon: "custom:ClockIcon", moduleName: "Time Management", type: "group" },
        { title: "Expert Management", key: "expertManagement", icon: "custom:ExpertManagement", moduleName: "Expert Management", type: "group" },
        { title: "Order Management", key: "orderManagement", icon: "custom:OrderManagement", moduleName: "Order Management", type: "group" },
        { title: "Partners & Entities", key: "partnersAndEntities", icon: "custom:PartnersAndEntities", moduleName: "Partners & Entities", type: "group" },
        { title: "Billing", key: "billing", icon: "custom:billingIcon", moduleName: "Billing", type: "group" },
        { title: "Project & Reference", key: "projectsAndReferences", icon: "custom:projectsIcon", moduleName: "Project & Reference", type: "group" },
        { title: "Flight Management", key: "flightManagement", icon: "custom:planeIcon", moduleName: "Flight Management", type: "group" },
        { title: "Foreign Employees & Offices", key: "foreignEmployeesAndOffices", icon: "custom:ForeignEmployeeIcon", moduleName: "Foreign Employees & Offices", type: "group" },
        { title: "Invoices", key: "invoices", icon: "custom:InvoicesIcon", moduleName: "Invoices", type: "group", },
        { title: "Accounting", key: "accounting", icon: "custom:accountingIcon", moduleName: "Accounting", type: "group" },
        // { title: "Controlling", key: "controlling", icon: "mdi-id-card", moduleName: "Controlling", type: "group" },
        { title: "Jobbase", key: "jobbase", icon: "custom:jobIcon", moduleName: "Jobbase", type: "list-item", route: "/jobbase"},
        { title: "Reporting", key: "reporting", icon: "custom:reportingIcon", moduleName: "Reporting", type: "group" },
        { title: "User Management", key: "userManagement", icon: "custom:UserManagementIcon", moduleName: "User Management", type: "list-item", route: "/user-management" },
        { title: "Settings", key: "settings", icon: "custom:settingsIcon", moduleName: "Settings", type: "group" },
      ],
      // ['sidebar title', 'route', 'access level name']
      yourSpace: [
        ["Profile", "/profile", "Profile"],
        ["Project Hours", "/your-project-hours", "Project Hours"],
        ["Time Off", "/your-time-off", "Time Off"],
        ["Advance Payment", "/your-advance-payment", "Advance Payment Requests"],
        ["Internal Missions", "/your-internal-missions", "Internal Mission Requests"],
        ["Flight Requests", "/your-flight-requests", "Flight Requests"],
        ["Entertainment Requests", "/your-entertainment-requests", "Entertainment Requests"],
      ],
      employmentManagement: [
        ["Staff directory", "/employees", "Staff directory"],
        ["My team members", "/my-team-employees", "My Team"],
        ["Leaves", "/leaves", "Leaves"],
        ["Maternity/Parental Leave", "/maternity-parental-leaves", "Maternity/Parental Leave"],
        ["Announcements", "/announcements", "Announcement"]
      ],
      expertManagement: [
        ["Experts", "/experts", "Expert"],
        ["Expert Contract", "/all-expert-contracts", "Expert Contract"],
        ["Employment Contract", "/all-employment-contracts", "Employment Contract"],
      ],
      orderManagement: [
          ["Planning", "/planning", "Planning"],
          ["Internal Mission", "/internal-mission-plans", "Internal Mission"],
          ["Advance Payment", "/advance-payments", "Advance Payment"],
          ["Entertainment", "/entertainments", "Entertainment"],
          ["Flights", "/planning", "Flights"],
      ],
      partnersAndEntities: [
        ["Entities", "/entities", "Entities"],
        ["Partner Contract and Other Invoices", "/sub-contract-and-manual-expenses", "Partner Contract & Other Invoices"],
      ],
      projectsAndReferences: [
        ["Projects", "/projects", "Projects"],
        ["References", "/references", "References"],
        ["Cost Center", "/cost-center", "Cost Center"],
        ["Project Local Funds", "/pm-locals", "Project Local Funds"],
      ],
      foreignEmployeesAndOffices: [
        ["Foreign Employee", "/foreign-employees", "Foreign Employee"],
        ["Foreign Office", "/foreign-office", "Foreign Office"],
      ],
      invoices: [
        ["Incoming Invoices", "/incoming-invoices", "Incoming Invoices"],
        ["Outgoing Invoices", "/outgoing-invoices", "Outgoing Invoices"],
      ],
      billings: [
        ["Advance Payment", "/advance-payments", "Advance Payment"],
        ["Entertainment", "/entertainments", "Entertainment"],
        ["Flights", "/flight", "Flight"],
        ["Internal Mission", "/internal-mission-plans", "Internal Mission"],

      ],
      userManagement: [
        ["User Management", "/user-management", "User Management"],
      ],
      settings: [
        ["Access Role", "/access-role", "Access Role"],
        ["General Configuration", "/hr-configuration", "General Configuration"],
        ["Project Configurations", "/project-configuration", "Project Configuration"],
        ["Templates", "/setting-templates", "Templates"]
      ],
      reporting: [
        ["Accounting", "/accounting-reporting", "Accounting"],
        ["Controlling", "/controlling-reporting", "Controlling Reporting"],
        ["Contract And Order", "/contract-and-order-reporting", "Contract & Order"],
        ["Human Resources", "/human-resources-reporting", "Human Resources"],
        ["Proposal", "/proposal-reporting", "Proposal"],
        ["Project", "/project-reporting", "Project Reporting"]
      ],
      flightManagement: [
        ["Flight Overview", "/flight-overview", "Flight Overview"],
        ["Flight Settlement", "/flight-settlement", "Flight Settlement"],
      ],
      timeManagement: [
        ["Working Hours", "/working-time-logs", "Working Hours"],
        ["Project Hours", "/project-hours-logs", "Project Hours"],
        ["Team Hours", "/team-hours-logs", "Team Hours"]
      ]
    };
  },
  computed: {
    showMenu() {
      return this.$store.getters["auth/isShowNavMenu"];
    },
    accounting() {
      return [
        ["Account Balance Overview", "/account-balance-overview", "Account Balance Overview"],
        ["Account Cash/Bank", "/account-cash-bank", "Account Cash/Bank"],
        ["Accruals", "/accruals", "Accruals"],
        ["Accruals Mirror", "/accruals-mirror", "Accruals Mirror"],
        ["Bank Account", "/bank-account", "Bank Account"],
        ["Currency Consumption", "/currency-consumption", "Currency Consumption"],
        ["Currency Exchange", "/currency-exchange", "Currency Exchange"],
        ["Currency Purchase", "/currency-purchase", "Currency Purchase"],
        ["Loan Guarantees", "/guarantees", "Loan Guarantees"],
        ["Travelling Expenses", "/travelling-expenses", "Travelling Expenses"],
        ["Break Even Point", "/break-even", "Break Even Point"],
        ["Project Result Balance Sheet", "/project-result-balance-sheet", "Project Result Balance Sheet"],
      ]
    },
    moduleNames() {
      const permissions = this.$store.getters["auth/permissions"];
      return [{module_name: "Home", parent_name: ""}, ...extractModuleNamesAsObject(permissions)];
    },
  },

  methods: {
    isModuleAccessible(moduleName) {

      return this.moduleNames.find((module) => module.module_name === moduleName && module.parent_name === "");
    },
    getTranslatedStringKey(field) {
      return getTransString(field);
    },
    checkSubModulePermissions(module, parent) {
      const modules = {
        foreignEmployeesAndOffices: this.foreignEmployeesAndOffices,
        "billing": this.billings,
        projectsAndReferences: this.projectsAndReferences,
        "accounting": this.accounting,
        "userManagement": this.userManagement,
        "yourSpace": this.yourSpace,
        reporting: this.reporting,
        "references": this.references,
        "flightManagement": this.flightManagement,
        timeManagement: this.timeManagement,
        settings: this.settings,
        employmentManagement: this.employmentManagement,
        expertManagement: this.expertManagement,
        orderManagement: this.orderManagement,
        partnersAndEntities: this.partnersAndEntities,
        invoices: this.invoices,
      }

      return modules[module]?.filter(([, , name]) => {
        return this.moduleNames.some((module) => module.module_name === name && module.parent_name === parent);
      });
    },
    handleProceed(route) {
      if (route) {
        if (route === "/profile") {
          this.$store.commit("hrResources/SET_HOME_TO_STATE", false);
          this.$store.commit("hrResources/SET_CURRENT_TAB_TO_STATE", 0);
        }
        this.$router.push(route)
      }
    },
    toggleSubmodules(key) {
      for (let ref in this.$refs) {
        if (ref !== `${key}_nav_item`) {
          this.$refs[ref][0].isSubmodulesOpen = false
        } else {
          this.$refs[ref][0].isSubmodulesOpen = !this.$refs[ref][0].isSubmodulesOpen
        }
      }
    },
  },
  mounted() { }
});
</script>
<style scoped>
aside {
  position: absolute;
  top: 60px;
  left: 20px;
  z-index: 1001;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
