<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "Expert Management"
})
</script>

<template>
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M8 15H16V14.45C16 13.7 15.6333 13.1042 14.9 12.6625C14.1667 12.2208 13.2 12 12 12C10.8 12 9.83333 12.2208 9.1 12.6625C8.36667 13.1042 8 13.7 8 14.45V15ZM12 11C12.55 11 13.0208 10.8042 13.4125 10.4125C13.8042 10.0208 14 9.55 14 9C14 8.45 13.8042 7.97917 13.4125 7.5875C13.0208 7.19583 12.55 7 12 7C11.45 7 10.9792 7.19583 10.5875 7.5875C10.1958 7.97917 10 8.45 10 9C10 9.55 10.1958 10.0208 10.5875 10.4125C10.9792 10.8042 11.45 11 12 11ZM8 21V19H4C3.45 19 2.97917 18.8042 2.5875 18.4125C2.19583 18.0208 2 17.55 2 17V5C2 4.45 2.19583 3.97917 2.5875 3.5875C2.97917 3.19583 3.45 3 4 3H20C20.55 3 21.0208 3.19583 21.4125 3.5875C21.8042 3.97917 22 4.45 22 5V17C22 17.55 21.8042 18.0208 21.4125 18.4125C21.0208 18.8042 20.55 19 20 19H16V21H8ZM4 17H20V5H4V17Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="0.3"
    />
  </svg>
</template>

<style scoped>

</style>