import {RouteRecordRaw} from "vue-router";
import {requireAuth} from "@/router/routeServices";


const routes: Array<RouteRecordRaw> = [
    {
        path: "/projects",
        name: "projects",
        component: () =>
            import("../views/dashboard/projects/projects/Projects.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Project & Reference"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/add-project",
        name: "addProject",
        component: () =>
            import("../views/dashboard/projects/projects/AddProject.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Project & Reference"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/cost-center",
        name: "costCenter",
        component: () =>
            import("@/components/dashboard/hr-settings/CostCenters.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Project & Reference"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/add-cost-center",
        name: "addCostCenter",
        component: () => import("../views/dashboard/cost-center/CostCenter.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Project & Reference"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/preview-print",
        name: "previewPrint",
        component: () =>
            import("../views/dashboard/projects/projects/PrintPreview.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Project & Reference"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/edit-project",
        name: "editProject",
        component: () =>
            import("../views/dashboard/projects/projects/EditProject.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Project & Reference"],
        },
        beforeEnter: requireAuth,
        redirect: {name: "projectInformation"},
        children: [
            {
                path: "project-information",
                name: "projectInformation",
                component: () => import("@/components/dashboard/projects/BasicInformation.vue"),
            },
            {
                path: "project-calculations",
                name: "projectCalculations",
                component: () => import("@/components/dashboard/projects/ProjectCalculations.vue"),
            },
            {
                path: "project-contracts",
                name: "projectContracts",
                component: () => import("@/components/dashboard/projects/ProjectContract.vue"),
            },
            {
                path: "foreign-establishments",
                name: "foreignEstablishments",
                component: () => import("@/components/dashboard/projects/ForeignEstablishment.vue"),
            },
            {
                path: "bank-guarantee",
                name: "bankGuarantee",
                component: () => import("@/components/dashboard/projects/BankGuarantee.vue"),
            },
            {
                path: "advance-payment",
                name: "projectAdvancePayment",
                component: () => import("@/components/dashboard/projects/AdvancePayments.vue"),
            },
            {
                path: "interim-payment",
                name: "projectInterimPayment",
                component: () => import("@/components/dashboard/projects/InterimPayements.vue"),
            },
            {
                path: "Project-budget",
                name: "projectBudget",
                component: () => import("@/components/dashboard/projects/ProjectBudget.vue"),
            },
            {
                path: "project-local-budget",
                name: "projectLocalBudget",
                component: () => import("@/components/dashboard/projects/ProjectLocalBudget.vue"),
            },
            {
                path: "planning-tool",
                name: "planningTool",
                component: () => import("@/components/dashboard/projects/PlanningTool.vue"),
            },
            {
                path: "expert-contracts",
                name: "expertContracts",
                component: () => import("@/components/dashboard/projects/contracts/Contracts.vue"),
            },
            {
                path: "employment-contracts",
                name: "employmentContracts",
                component: () => import("@/components/dashboard/projects/employment-settlement/EmploymentMission.vue"),
            },
            {
                path: "project-orders",
                name: "projectOrders",
                component: () => import("@/components/dashboard/projects/ProjectOrders.vue"),
            },
            {
                path: "project-files",
                name: "projectFiles",
                component: () => import("@/components/dashboard/projects/ProjectFiles.vue"),
            },
            {
                path: "project-milestones",
                name: "projectMilestones",
                component: () => import("@/components/dashboard/projects/splitBudgetComponents/MilestonesData.vue"),
            },
            {
                path: "project-closure",
                name: "projectClosure",
                component: () => import("@/components/dashboard/projects/ProjectClosure.vue"),
            },
        ]
    },
    {
        path: "/project-result-balance-sheet",
        name: "projectResultBalanceSheet",
        component: () =>
            import("@/components/dashboard/projects/ProjectResultBalanceSheet.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Projects"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/all-foreign-establishment",
        name: "foreignEstablishmentModule",
        component: () =>
            import(
                "@/views/dashboard/foreignEstablishment/ForeignEstablishmentModule.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Projects"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/pm-locals",
        name: "pmLocals",
        component: () =>
            import("@/views/dashboard/projects/pm-locals/PmLocals.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Project & Reference"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/edit-pm-local",
        name: "editPmLocal",
        component: () =>
            import("@/views/dashboard/projects/pm-locals/EditPmLocal.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Project & Reference"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/view-establishment-details",
        name: "singleEstablishment",
        component: () =>
            import("@/components/dashboard/projects/SingleEstablishment.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Projects"],
            isEdit: true,
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/deployment-agreement",
        name: "deployment-agreement",
        component: () =>
            import("../views/dashboard/projects/projects/DeploymentAgreement.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Projects"],
        },
        beforeEnter: requireAuth,
    },
]

export default routes;