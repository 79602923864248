import Api from "@/services/api";
import { ActionContext } from "vuex";

import {
  getAllJobBase,
  manageJobBase,
  getAllProjectsForJobbase,
  getAllJobApplication,
  getSingleJobApplication,
  updateJobApplication
} from "@/services/endpoints";

export default {
  namespaced: true,
  state: {
    currentPage: 1,
    totalPages: null,
    totalData: null,
    allJobbase: [],
    currentJob: {},
    jobID: "",
    allProjects: [],
    appLoading: false,
    isEditMode: false,
    jobbaseTitle: "",
    isDuplicated: false,
    isPreview: false,
    allJobApplication: [],
    singleJobApplication:{},
    apiResponse: {},
    apiError: null,
  },

  mutations: {
    SET_ALL_JOBS_TO_STATE(state: any, payload: any) {
      state.allJobbase = payload;
    },
    SET_JOB_ID_STATE(state: any, payload: any) {
      state.jobID = payload;
    },
    SET_CURRENT_JOB_TO_STATE(state: any, payload: any) {
      state.currentJob = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_ALL_PROJECTS_TO_STATE(state: any, payload: any) {
      state.allProjects = payload;
    },
    SET_EDIT_MODE_TO_STATE(state: any, payload: any) {
      state.isEditMode = payload;
    },
    SET_TITLE_TO_STATE(state: any, payload: any) {
      state.jobbaseTitle = payload;
    },
    SET_DUPLICATE_JOB_STATE(state: any, payload: any) {
      state.isDuplicated = payload;
    },
    SET_PREVIEW_JOB_STATE(state: any, payload: any){
      state.isPreview = payload
    },
    SET_ALL_JOB_APPLICATION_TO_STATE (state: any, payload: any){
      state.allJobApplication= payload
    },
    SET_SINGLE_JOB_APPLICATION_TO_STATE (state: any, payload: any){
      state.singleJobApplication= payload
    },
    SET_API_RESPONSE_TO_STATE (state: any, payload: any){
      state.apiResponse = payload
    },
    SET_API_ERROR_TO_STATE (state: any, payload: any){
      state.apiError = payload
    }
  },
  getters: {
    allJobbase: (state: any) => state.allJobbase,
    currentJob: (state: any) => state.currentJob,
    currentPage: (state: any) => state.currentPage,
    totalPages: (state: any) => state.totalPages,
    jobID: (state: any) => state.jobID,
    appLoading: (state: any) => state.appLoading,
    allProjects: (state: any) => state.allProjects,
    getEditMode: (state: any) => state.isEditMode,
    getJobbaseTitle: (state: any) => state.jobbaseTitle,
    getIsDuplicated: (state: any) => state.isDuplicated,
    getPreview: (state: any) => state.isPreview,
    getAppliedJobs: (state: any) => state.allJobApplication,
    getSingleAppliedJobs: (state: any) => state.singleJobApplication,
    getApiResponse: (state: any) => state.apiResponse,
    getApiError: (state: any) => state.apiError,
  },
  actions: {
    fetchAllJobBase(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${getAllJobBase}${payload.page_number}/?type=${
            payload.type || ""
          }&status=${payload.status || ""}&search=${payload.search || ""}`
        )
        .then((response: any) => {
          if (response) {
            const responseData = response.data.data.map((job: any) => {
              return {
                ...job,
                country: job.country ? job.country.name : "",
              };
            });
            context.commit("SET_ALL_JOBS_TO_STATE", responseData);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },

    getSingleJob(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${manageJobBase}${context.getters.jobID}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_CURRENT_JOB_TO_STATE", responseData.data);
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getAllProjectsForJobbase(context: ActionContext<any, any>) {
      Api()
        .get(`${getAllProjectsForJobbase}`)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_ALL_PROJECTS_TO_STATE", response.data.data);
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getAllJobApplication(context: ActionContext<any, any>, payload:any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${getAllJobApplication}${payload.job_application_Id}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_ALL_JOB_APPLICATION_TO_STATE", response.data.data);
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getSingleJobApplication(context: ActionContext<any, any>, payload:any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${updateJobApplication}${payload.job_application_Id}/`, payload.data )
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_SINGLE_JOB_APPLICATION_TO_STATE", response.data.data);
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    updateAppliedJob(context: ActionContext<any, any>, payload:any) {
    
      Api()
        .put(`${getSingleJobApplication}${payload.applied_job_id}/`, payload.data)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_API_RESPONSE_TO_STATE", response.data);
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_API_ERROR_TO_STATE", error?.response?.data);
          }
        });
    },
  },
};
