<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "planeIcon",
});
</script>

<template>
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M7 16C7 15.45 7.19583 14.9792 7.5875 14.5875C7.97917 14.1958 8.45 14 9 14C9.55 14 10.0208 14.1958 10.4125 14.5875C10.8042 14.9792 11 15.45 11 16C11 16.55 10.8042 17.0208 10.4125 17.4125C10.0208 17.8042 9.55 18 9 18C8.45 18 7.97917 17.8042 7.5875 17.4125C7.19583 17.0208 7 16.55 7 16ZM12 14H18C18.55 14 19.0208 14.1958 19.4125 14.5875C19.8042 14.9792 20 15.45 20 16V23H18V21H6V23H4V13H6V19H12V14ZM15.75 13L12.8 7.65L10.15 10.3L10.4 12L9.65 12.75L8.475 10.55L6.275 9.35L7.025 8.6L8.725 8.825L11.375 6.175L6 3.25L6.95 2.3L13.55 4L16.25 1.3C16.45 1.1 16.6917 1 16.975 1C17.2583 1 17.5 1.1 17.7 1.3C17.9 1.5 18 1.74167 18 2.025C18 2.30833 17.9 2.55 17.7 2.75L15 5.45L16.7 12.05L15.75 13ZM18 19V16H14V19H18Z"
        fill="#344054"
        stroke-width="0.3"
    />
  </svg>
</template>

<style scoped>
svg {
  stroke: currentColor !important;
}
</style>
