import {RouteRecordRaw} from "vue-router";
import {requireAuth} from "@/router/routeServices";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/working-time-logs",
        name: "workingTimeLogs",
        component: () =>
            import("@/views/dashboard/timeLogs/WorkingTimeLogsView.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Time Management"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/project-hours-logs",
        name: "projectHoursLogs",
        component: () =>
            import("@/views/dashboard/timeLogs/ProjectHoursLogsView.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Time Management"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/team-hours-logs",
        name: "teamHoursLogs",
        component: () =>
            import("@/views/dashboard/timeLogs/TeamHoursView.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Time Management"],
        },
        beforeEnter: requireAuth,
    },
]

export default routes;