import {RouteRecordRaw} from "vue-router";
import {requireAuth} from "@/router/routeServices";


const routes: Array<RouteRecordRaw> = [
    {
        path: "/employees",
        name: "employees",
        component: () => import("../views/dashboard/employees/Employees.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Employee Management"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/my-team-employees",
        name: "myTeamEmployees",
        component: () => import("../views/dashboard/employees/Employees.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Employee Management"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/edit-employee",
        name: "editEmployee",
        component: () => import("../views/dashboard/employees/EditEmployee.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Employee Management"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/announcements",
        name: "Announcements",
        component: () => import("@/views/dashboard/employees/MyAnnouncements.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Employee Management"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/maternity-parental-leaves",
        name: "maternityParentalLeaves",
        component: () =>
            import(
                "@/components/dashboard/maternityParentalLeaves/MaternityParentalLeaves.vue"
                ),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Employee Management"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/add-employee",
        name: "addEmployee",
        component: () => import("../views/dashboard/employees/AddEmployee.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Employee Management"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/leaves",
        name: "leaves",
        component: () => import("../views/dashboard/employees/HrLeave.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: ["Employee Management"],
        },
        beforeEnter: requireAuth,
    },
    {
        path: "/profile",
        name: "profile",
        component: () => import("../views/dashboard/profile/Profile.vue"),
        meta: {
            allowAnonymous: false,
            layout: "dashboard",
            permissions: [],
        },
        beforeEnter: requireAuth,
    },
];

export default routes;