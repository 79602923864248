import Api from "@/services/api";
import { ActionContext } from "vuex";
import {
  getAllProjectReferences,
  getSingleProjectReference,
  projectReferenceApproval,
  getSelectedProjects,
  referenceAdvanceSearch,
  getReferenceAdvanceSearchHistory,
  getReferenceTemplatesByLanguage,
  generateReferenceFile,
  getUnPaginatedProjectsWithoutReference,
  getUnPaginatedProjectsWithReference,
} from "@/services/endpoints";
import { removeDuplicates } from "@/services/helpers";

export default {
  namespaced: true,
  state: {
    currentPage: 1,
    totalPages: null,
    totalData: null,
    appLoading: false,
    allProjectReferences: [],
    currentProjectReferences: {},
    currentProjectReferencesId: null,
    recentChanges: [],
    headers: [
      { label: "Cost Center", key: "cost_centre__cost_center_full_number" },
      { label: "Project Title", key: "project_title" },
      { label: "Project Status", key: "project_status" },
    ],
    selectedProject: [],
    selectedProjectIds: [],
    language: "en",
    selectedTemplate: "",
    selectedCompanyId: null,
    allAdvanceSearchHistory: [],
    allTemplatesByLanguage: [],
    responseData: {},
    errorData: {},
    projectDetails: {},
    referenceIds: null,
    projectsWithoutReference: null,
    projectsWithReference: null,
  },
  mutations: {
    SET_PROJECT_REFERENCES_TO_STATE(state: any, payload: any) {
      state.allProjectReferences = payload;
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_HEADER_TO_STATE(state: any, payload: any) {
      state.headers = payload;
    },
    REMOVE_HEADER_FROM_STATE(state: any, key: any) {
      state.headers = state.headers.filter((header: any) => header.key !== key);
    },
    SET_CURRENT_PROJECT_REFERENCES_TO_STATE(state: any, payload: any) {
      state.currentProjectReferences = payload;
    },
    SET_CURRENT_PROJECT_REFERENCES_ID_TO_STATE(state: any, payload: any) {
      state.currentProjectReferencesId = payload;
    },
    SET_RECENT_CHANGES_TO_STATE(state: any, payload: any) {
      state.recentChanges = payload;
    },
    SET_SELECTED_PROJECT_TO_STATE(state: any, payload: any) {
      // Remove duplicates based on id
      const uniqueIds = new Set();
      const uniqueProjects = [];

      for (const project of [...state.selectedProject, ...payload]) {
        if (!uniqueIds.has(project.id)) {
          uniqueIds.add(project.id);
          uniqueProjects.push(project);
        }
      }

      state.selectedProject = uniqueProjects;
    },
    CLEAR_SELECTED_PROJECT_STATE(state: any, payload: any){
      state.selectedProject = payload;
    },
    SET_SELECTED_PROJECT_IDS_TO_STATE(state: any, payload: any) {
      const ids = [...state.selectedProjectIds, payload];
      state.selectedProjectIds = ids;
    },
    SET_COST_CENTER_PROJECT_TO_STATE(state: any, payload: any) {
      const data = removeDuplicates(
        [...state.selectedProject, ...payload],
        "project_title"
      );
      state.selectedProject = data;
    },
    SET_PROJECT_WITHOUT_REFERENCE_TO_STATE(state: any, payload: any) {
      state.projectsWithoutReference = payload;
    },
    SET_PROJECT_WITH_REFERENCE_TO_STATE(state: any, payload: any) {
      state.projectsWithReference = payload;
    },
    SET_PROJECT_DETAILS_TO_STATE(state: any, payload: any) {
      state.projectDetails = payload;
    },
    SET_LANGUAGE_TO_STATE(state: any, payload: any) {
      state.language = payload;
    },
    SET_SELECTED_TEMPLATE_TO_STATE(state: any, payload: any) {
      state.selectedTemplate = payload;
    },
    SET_SELECTED_COMPANY_ID_TO_STATE(state: any, payload: any) {
      state.selectedCompanyId = payload;
    },
    SET_ADVANCE_SEARCH_HISTORY_TO_STATE(state: any, payload: any) {
      state.allAdvanceSearchHistory = payload;
    },
    SET_TEMPLATES_BY_LANGUAGE_TO_STATE(state: any, payload: any) {
      state.allTemplatesByLanguage = payload;
    },
    SET_RESPONSE_DATA(state: any, payload: any) {
      state.responseData = payload;
    },
    SET_ERROR_DATA(state: any, payload: any) {
      state.errorData = payload;
    },
    SET_REFERENCE_IDS_TO_STATE(state: any, payload: any) {
      state.referenceIds = payload;
    },
  },
  getters: {
    allProjectReferences: (state: any) => {
      return state?.allProjectReferences;
    },
    appLoading: (state: any) => {
      return state.appLoading;
    },
    currentPage: (state: any) => {
      return state.currentPage;
    },
    totalItems: (state: any) => {
      return state.totalPages;
    },
    tableHeaders: (state: any) => {
      return state?.headers;
    },
    getCurrentProjectReferences: (state: any) => {
      return state.currentProjectReferences;
    },
    currentReferenceId: (state: any) => {
      return state.currentProjectReferencesId;
    },
    recentChanges: (state: any) => {
      const data = state.recentChanges;
      return data;
    },
    selectedProjects: (state: any) => {
      return state.selectedProject;
    },
    selectedProjectIDs: (state: any) => {
      return state.selectedProject?.map((item: any) => item.id);
    },
    getLanguage: (state: any) => {
      return state.language;
    },
    getSelectedTemplate(state: any) {
      return state.selectedTemplate;
    },
    getSelectedCompanyId(state: any) {
      return state.selectedCompanyId;
    },
    getAdvanceSearchHistory(state: any) {
      return state.allAdvanceSearchHistory;
    },
    getReferenceForm(state: any) {
      return {
        comments: state.comments,
        project_descriptions:
          state.currentProjectReferences.project_descriptions,
        services_provided: state.currentProjectReferences.services_provided,
        short_description: state.currentProjectReferences.short_description,
        short_description_giz:
          state.currentProjectReferences.short_description_giz,
        website_description: state.currentProjectReferences.website_description,
        is_completion_of_certificate:
          state.currentProjectReferences.is_completion_of_certificate,
        documents: state.currentProjectReferences.documents,
        website_picture: state.currentProjectReferences.website_picture,
        project_titles: state.currentProjectReferences.project_titles,
      };
    },
    getTemplatesByLanguage(state: any) {
      return state.allTemplatesByLanguage;
    },
    getResponseData(state: any) {
      return state.responseData;
    },
    getErrorData(state: any) {
      return state.errorData;
    },
    getProjectDetails(state: any) {
      return state.projectDetails;
    },
    getReferenceIdsForFileGeneration(state: any): any[] {
      // Remove duplicates from the referenceIds array
      return Array.from(new Set(state.referenceIds));
    },
    getProjectsWithoutReference(state: any) {
      return state.projectsWithoutReference;
    },
    getProjectsWithReference(state: any) {
      return state.projectsWithReference;
    },
  },
  actions: {
    getAllProjectReferences(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${getAllProjectReferences}${payload.page_number}/?search=${
            payload.search || ""
          }&project_manager=${payload.project_manager || ""}&controller=${
            payload.controller || ""
          }&country=${payload.country || ""}&year=${
            payload.year || ""
          }&approval_status=${payload.approval_status || ""}&language=${
            payload.language || ""
          }`
        )
        .then((response: any) => {
          if (response.status === 200) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit("SET_PROJECT_REFERENCES_TO_STATE", response.data);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        })

        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },

    getSingleProjectReference(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${getSingleProjectReference}${context.getters.currentReferenceId}/`
        )
        .then((response: any) => {
          if (response.status === 200) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit(
              "SET_CURRENT_PROJECT_REFERENCES_TO_STATE",
              response.data
            );
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    projectReferenceApproval(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }

      Api()
        .get(
          `${projectReferenceApproval}${context.getters.currentReferenceId}/`
        )
        .then((response: any) => {
          if (response.status === 200) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit("SET_RECENT_CHANGES_TO_STATE", response.data.data);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        })
        .catch((error: any) => {
          // console.log(error.response, "hospitality package")
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },

    getSelectedProjects(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .post(`${getSelectedProjects}`, {
          project_ids: payload.id,
        })
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit("SET_SELECTED_PROJECT_TO_STATE", response.data.data);
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getProjectsWithReference(context: ActionContext<any, any>) {
      Api()
        .put(`${getUnPaginatedProjectsWithReference}`)
        .then((response) => {
          if (response.data.status === "success") {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit(
              "SET_PROJECT_WITH_REFERENCE_TO_STATE",
              response.data.data
            );
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getProjectsWithoutReference(context: ActionContext<any, any>) {
      Api()
        .get(`${getUnPaginatedProjectsWithoutReference}`)
        .then((response) => {
          if (response.data.status === "success") {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit(
              "SET_PROJECT_WITHOUT_REFERENCE_TO_STATE",
              response.data.data
            );
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },

    referenceAdvanceSearch(context: ActionContext<any, any>, payload: any) {
      Api()
        .post(`${referenceAdvanceSearch}`, payload)
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit("SET_PROJECT_REFERENCES_TO_STATE", response.data);
            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getReferenceAdvanceSearchHistory(context: ActionContext<any, any>) {
      Api()
        .get(`${getReferenceAdvanceSearchHistory}`)
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit(
              "SET_ADVANCE_SEARCH_HISTORY_TO_STATE",
              response.data.data
            );
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getReferenceTemplatesByLanguage(
      context: ActionContext<any, any>,
      payload: any
    ) {
      Api()
        .get(`${getReferenceTemplatesByLanguage}?language=${payload.language}`)
        .then((response) => {
          if (response.status === 200) {
            context.commit(
              "SET_TEMPLATES_BY_LANGUAGE_TO_STATE",
              response.data.data
            );
          }
        });
    },
    async generateReferenceFile(
      context: ActionContext<any, any>,
      payload: any
    ) {
      try {
        const response = await Api().post(`${generateReferenceFile}`, payload);
        if (response.data.status === "success")
          context.commit("SET_RESPONSE_DATA", response.data);
      } catch (error: any) {
        context.commit("SET_ERROR_DATA", error.response.data);
      }
    },
  },
};
