import Api from "@/services/api";
import { ActionContext } from "vuex";
import { getAllProjectClosures, endInvoicing } from "@/services/endpoints";

export default {
  namespaced: true,
  state: {
    projectClosureStatus: {},
    apiResponse: {},
    apiError: {},
    appLoading: false,
  },
  mutations: {
    SET_ALL_PROJECT_CLOSURE(state: any, payload: any) {
      state.projectClosureStatus = payload;
    },
    SET_API_RESPONSE(state: any, payload: any) {
      state.apiResponse = payload;
    },
    SET_API_ERROR(state: any, payload: any) {
      state.apiError = payload;
    },
    SET_APP_LOADING(state: any, payload: any) {
      state.appLoading = payload;
    }
  },
  getters: {
    allProjectClosureStatus: (state: any) => state.projectClosureStatus,
    apiResponse: (state: any) => state.apiResponse,
    apiError: (state: any) => state.apiError,
    appLoading: (state: any) => state.appLoading,
  },
  actions: {
    fetchProjectClosureStatus(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page){
        context.commit("SET_APP_LOADING", payload.load_page)
      }
      Api()
        .get(`${getAllProjectClosures}${payload.projectId}`)
        .then((response) => {
          context.commit("SET_APP_LOADING", false);
          context.commit("SET_ALL_PROJECT_CLOSURE", response.data?.data);
        })
        .catch((error) => {
          if(error){
            context.commit("SET_APP_LOADING", false);
          }
        });
    },
    async updateProjectClosureStatus(
      context: ActionContext<any, any>,
      payload: any
    ) {
      context.commit("SET_APP_LOADING", payload.load_page);
      try {
        const response = await Api().put(
          `${getAllProjectClosures}${payload.projectId}/`,
          payload.data
        );
        if (response) {
          context.commit("SET_APP_LOADING", false);
          context.commit("SET_API_RESPONSE", response.data?.data);
        }
      } catch (error) {
        context.commit("SET_API_ERROR", error);
      }
    },
    async endInvoicing(
      context: ActionContext<any, any>,
      payload: any
    ) {
      try {
        const response = await Api().put(
          `${endInvoicing}${payload.projectId}/`,
          payload.data
        );
        context.commit("SET_API_RESPONSE", response.data);
      } catch (error) {
        context.commit("SET_API_ERROR", error);
      }
    },
  },
};
