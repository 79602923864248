import Api from "@/services/api";
import { ActionContext } from "vuex";
import {
  addOrUpdateBudgetItem,
  deleteBudgetItem,
  postBudgetSectionUrl,
  patchBudgetSectionUrl,
  deleteBudgetSectionUrl,
  updateBudgetSectionLineItemUrl,
  getAllBudgetSectionUrl,
  budgetBasicVersionSave,
  getPostBudgetCalculation,
  getBudgetBasicVersion,
  updateBudgetBasicVersion,
} from "@/services/endpoints";

interface BudgetSectionPayload {
  project?: number | string;
  title?: string;
  budgetSectionId?: number | string;
}

interface BudgetLineItem {
  id: number | string;
  structure: string;
  position: string;
  number_of_units: number | string;
  amount_per_unit: number | string;
  account: string;
  is_planning: boolean;
  row_index?: unknown;
}

export default {
  splitItem: "",
  createdSplit: "",
  splitType: "",
  mileStoneData: [],
  splitExist: false,
  saveSplit: null,
  splitEditMode: null,
  splitMode: null,
  currentYear: null,
  budgetMode: null,
  namespaced: true,
  totalPages: null,
  curentPage: 1,
  projectID: "",
  state: {
    structure: [
      "BOP - Fixed Fee",
      "BOP - Against Receipt",
      "Costs BOP",
      "Costs",
      "Cost Internal",
    ],
    bopStructure: ["Costs BOP"],
    account: [
      "4401 Personnel",
      "4201 Travel expenses",
      "4901 Other costs",
      "4902 Other costs receipt",
      "4301 Project funds local",
      "4302 Project funds local receipt",
    ],
    bankGuarantee: false,

    projectBudgets: {},
    projectBudgetSetionId: "",
    budgetLineItems: "",
    budgetData: [],
    initialBudgetData: [],
    totalItems: {},
    enabledButton: true,
    mandatorySection: "",
    error: {},
    response: {},
    budgetSections: {},
    budgetLineItemAfterRowRemove: [],
    appLoading: false,
    incomeCostPair: {},
    backstopping: 0,
    yearlyCostSubtotals: {},
    yearlyInternalCostSubtotals: {},
    yearlyIncomeSubtotals: {},
    allCostSubtotals: {},
    allIncomeSubtotals: {},
    allInternalCostSubtotals: {},
    viewExcel: true,
    excelData: [],
    savedBudgetVersion: false,
    allPostBudgetCalculation: {},
    basic_version: false,
    allBudgetData: {},
  },
  mutations: {
    SET_YEARLY_COST_SUBTOTALS(state: any, payload: any) {
      state.yearlyCostSubtotals[
        `${payload.section}-${payload.lineItem}-${payload.currentYear}`
      ] = payload.subTotal;
    },
    SET_YEARLY_INTERNAL_COST_SUBTOTALS(state: any, payload: any) {
      state.yearlyInternalCostSubtotals[
        `${payload.section}-${payload.lineItem}-${payload.currentYear}`
      ] = payload.subTotal;
    },
    SET_YEARLY_INCOME_SUBTOTALS(state: any, payload: any) {
      state.yearlyIncomeSubtotals[
        `${payload.section}-${payload.lineItem}-${payload.currentYear}`
      ] = payload.subTotal;
    },

    SET_ALL_COST_SUBTOTALS(state: any, payload: any) {
      state.allCostSubtotals[
        `${payload.section}-${payload.lineItem}-${payload.currentYear}`
      ] = payload.subTotal;
    },
    SET_ALL_INCOME_SUBTOTALS(state: any, payload: any) {
      state.allIncomeSubtotals[
        `${payload.section}-${payload.lineItem}-${payload.currentYear}`
      ] = payload.subTotal;
    },
    SET_ALL_INTERNAL_COST_SUBTOTALS(state: any, payload: any) {
      state.allInternalCostSubtotals[
        `${payload.section}-${payload.lineItem}-${payload.currentYear}`
      ] = payload.subTotal;
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_ENABLED_BUTTON_TO_STATE(state: any, payload: any) {
      state.enabledButton = payload;
    },
    SET_TOTAL_ITEMS_TO_STATE(state: any, payload: any) {
      state.totalItems = payload;
    },
    SET_SPLIT_TO_STATE(state: any, payload: any) {
      state.splitItem = payload;
    },
    SET_SPLITEXIST_TO_STATE(state: any, payload: any) {
      state.splitExist = payload;
    },
    SET_BUDGET_EXCEL_DATA_TO_STATE(state: any, payload: any) {
      state.excelData = payload;
    },
    SET_SPLIT_MODE_TO_STATE(state: any, payload: any) {
      state.splitMode = payload;
    },
    SET_BUDGET_MODE_TO_STATE(state: any, payload: any) {
      state.budgetMode = payload;
    },
    SET_BANK_GUARANTEE_TO_STATE(state: any, payload: any) {
      state.bankGuarantee = payload;
    },

    SET_PROJECT_BUDGET_SECTION_ID_TO_STATE(state: any, payload: any) {
      state.projectBudgetSetionId = payload;
    },
    SET_ALL_PROJECT_BUDGETS_TO_STATE(state: any, payload: any) {
      state.projectBudgets = payload;
    },
    SET_ALL_PROJECT_BUDGETS_STATISTICS(state: any, payload: any) {
      for (const key in payload) {
        if (payload.key) {
          state.projectBudgets.total_statistics[key] = payload[key];
        }
      }
    },
    SET_BACKSTOPPING(state: any) {
      const mandatoryEntry = state.projectBudgets?.budget_sections?.find(
        (section: { title: string }) => section.title === "Mandatory"
      );

      if (mandatoryEntry) {
        // Find the budget item with position "Backstopping" within the "Mandatory" entry
        const backstoppingBudgetItem = mandatoryEntry.budget_items.find(
          (item: { position: string }) => item.position === "Backstopping"
        );

        if (backstoppingBudgetItem) {
          const totalAmount =
            backstoppingBudgetItem.amount_per_unit *
            backstoppingBudgetItem.number_of_units;
          state.backstopping = totalAmount;
        } else {
          state.backstopping = 0;
        }
      } else {
        state.backstopping = 0;
      }
    },

    SET_INCOME_COST_PAIR(state: any, payload: any) {
      state.incomeCostPair[payload.budget_section_id] = { ...payload.pair };
    },
    SET_BUDGET_DATA_TO_STATE(state: any, payload: any) {
      state.budgetData = payload;
    },
    SET_ONE_BUDGET_DATA_TO_STATE(state: any, payload: any) {
      state.budgetData[payload.tableIndex] = payload.sectionObject;
    },
    SET_INITIAL_BUDGET_DATA_TO_STATE(state: any, payload: any) {
      state.initialBudgetData = payload;
    },
    SET_BUDGET_LINE_ITEMS_TO_STATE(state: any, payload: any) {
      state.budgetLineItems = payload;
    },
    SET_CURENTYEAR_TO_STATE(state: any, payload: any) {
      state.currentYear = payload;
    },
    SET_SAVE_EDIT_SPLIT_TO_STATE(state: any, payload: any) {
      state.saveSplit = payload;
    },
    SET_SAVE_EDIT_SPLIT_MODE_TO_STATE(state: any, payload: any) {
      state.splitEditMode = payload;
    },
    SET_SPLIT_CREATED_TO_STATE(state: any, payload: any) {
      state.createdSplit = payload;
    },
    SET_SPLIT_TYPE_TO_STATE(state: any, payload: any) {
      state.splitType = payload;
    },
    SET_VIEW_EXCEL_TO_STATE(state: any, payload: any) {
      state.viewExcel = payload;
    },
    SET_MILESTONE_DATA_TO_STATE(state: any, payload: any) {
      state.mileStoneData = payload;
    },
    SET_TOTALPAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_CURRENTPAGE_TO_STATE(state: any, payload: any) {
      state.curentPage = payload;
    },
    SET_PROJECT_ID_STATE(state: any, payload: any) {
      state.projectID = payload;
    },
    SET_BUDGET_SECTION_TO_STATE(state: any, payload: any) {
      state.mandatorySection = payload;
    },
    SET_ERROR(state: any, data: any) {
      state.error = { ...data };
    },
    SET_RESPONSE(state: any, data: any) {
      state.response = { ...data };
    },
    REMOVE_BUDGET_SECTION(
      state: any,
      budgetSectionIdToRemove: number | string
    ) {
      const indexToRemove = state.projectBudgets?.budget_sections?.findIndex(
        (budgetSection: { id: string | number }) =>
          budgetSection.id === budgetSectionIdToRemove
      );
      if (indexToRemove !== -1) {
        state.projectBudgets?.budget_sections?.splice(indexToRemove, 1);
      }

      const indexToRemove2 = state.budgetData?.findIndex(
        (budgetSection: { budget_section_id: string | number }) =>
          budgetSection?.budget_section_id === budgetSectionIdToRemove
      );
      if (indexToRemove2 !== -1) {
        state.budgetData?.splice(indexToRemove2, 1);
      }
    },
    REMOVE_LINE_ITEM(state: any, budgetSectionIdToRemove: number | string) {
      const indexToRemove = state.projectBudgets?.budget_sections?.findIndex(
        (budgetSection: { id: string | number }) =>
          budgetSection.id == budgetSectionIdToRemove
      );
      if (indexToRemove !== -1) {
        state.projectBudgets?.budget_sections?.splice(indexToRemove, 1);
      }
    },
    APPEND_NEW_BUDGET_SECTION_TO_STORE(state: any, payload: any) {
      state.projectBudgets?.budget_sections?.push({
        budget_items: [],
        id: payload.id,
        profit_margin: 0,
        profit_margin_percentage: 0,
        title: payload.title,
        total_cost: 0,
        total_income: 0,
      });

      state.budgetData?.push({
        budget_items: [],
        budget_section_id: payload.id,
      });
    },
    REMOVE_SECTION_LINE_ITEM(state: any, payload: any) {
      const sectionIndex = state.budgetData?.findIndex(
        (budgetSection: { budget_section_id: string | number }) =>
          budgetSection.budget_section_id == payload.budgetSectionId
      );
      if (sectionIndex !== -1) {
        const lineItemIndex = state.budgetData[
          sectionIndex
        ]?.budget_items?.findIndex(
          (item: {
            row_index?: string | number;
            budget_item_id?: string | number;
          }) =>
            item.row_index == payload.rowIndex ||
            item.budget_item_id == payload.budgetItemId
        );

        if (lineItemIndex !== -1) {
          state.budgetData[sectionIndex]?.budget_items?.splice(
            lineItemIndex,
            1
          );
        }
      }
    },
    SET_LINE_ITEM_ON_ROW_REMOVE(state: any, payload: any) {
      const sectionIndex = state.projectBudgets?.budget_sections?.findIndex(
        (budgetSection: { id: string | number }) =>
          budgetSection.id == payload.budgetSectionId
      );

      if (sectionIndex !== -1) {
        state.budgetLineItemAfterRowRemove = [
          ...state.projectBudgets.budget_sections[sectionIndex].budget_items,
        ];
      }
    },
    SET_IS_PLANNING_ON_CHECKBOX_CHANGE(state: any, payload: any) {
      const sectionIndex = state.budgetData?.findIndex(
        (budgetSection: { budget_section_id: string | number }) =>
          budgetSection?.budget_section_id == payload.budgetSectionId
      );
      if (sectionIndex !== -1) {
        const lineItemIndex = state.budgetData[
          sectionIndex
        ]?.budget_items?.findIndex(
          (item: {
            row_index?: string | number;
            budget_item_id?: string | number;
          }) =>
            item.row_index == payload.rowIndex ||
            item.budget_item_id == payload.budgetItemId
        );

        if (lineItemIndex !== -1) {
          state.budgetData[sectionIndex].budget_items[
            lineItemIndex
          ].is_planning = payload.is_planning;
        }
      }
    },
    SET_POSITION_ON_EDIT(state: any, payload: any) {
      const sectionIndex = state.budgetData?.findIndex(
        (budgetSection: { budget_section_id: string | number }) =>
          budgetSection.budget_section_id == payload.budgetSectionId
      );
      if (sectionIndex !== -1) {
        const lineItemIndex = state.budgetData[
          sectionIndex
        ]?.budget_items?.findIndex(
          (item: {
            row_index?: string | number;
            budget_item_id?: string | number;
          }) =>
            item.row_index == payload.rowIndex ||
            item.budget_item_id == payload.budgetItemId
        );

        if (lineItemIndex !== -1) {
          state.budgetData[sectionIndex].budget_items[lineItemIndex].position =
            payload.position;
        }
      }
    },

    SET_RECALCULATED_RESULT_AFTER_REMOVED_LINE_ITEM(state: any, payload: any) {
      const index = state.budgetData.findIndex(
        (data: { budget_section_id: string | number }) =>
          data.budget_section_id === payload.budget_section_id
      );
      if (index !== -1) {
        // Edit the object with the new data
        state.budgetData[index].total_income = payload.total_income;
        state.budgetData[index].total_cost = payload.total_cost;
        state.budgetData[index].profit_margin = payload.profit_margin;
        state.budgetData[index].profit_margin_percentage =
          payload.profit_margin_percentage;
      }
    },
    SET_IS_SPLITTED(state: any, payload: boolean) {
      state.projectBudgets.is_splited = payload;
    },
    SET_SAVED_BUDGET_VERSION(state: any, payload: boolean) {
      state.savedBudgetVersion = payload;
    },
    SET_ALL_POST_BUDGET_CALCULATION(state: any, payload: any) {
      state.allPostBudgetCalculation = payload;
    },
    SET_BASIC_VERSION_MODE(state: any, payload: any) {
      state.basic_version = payload;
    },
    SET_ALL_BUDGET_DATA(state: any, payload: any) {
      state.allBudgetData = payload;
    },
  },
  getters: {
    isAppLoading(state: any) {
      return state.appLoading;
    },
    isMandatorySection(state: any) {
      return state.mandatorySection;
    },
    isEnabledButton(state: any) {
      return state.enabledButton;
    },
    isStructure(state: any) {
      return state.structure;
    },
    isBopStructure(state: any) {
      return state.bopStructure;
    },
    isAccount(state: any) {
      return state.account;
    },
    isSplit(state: any) {
      return state.splitItem;
    },
    isSplitExist(state: any) {
      return state.splitExist;
    },
    isSplitMode(state: any) {
      return state.splitMode;
    },
    isBudgetMode(state: any) {
      return state.budgetMode;
    },
    isViewExcel(state: any) {
      return state.viewExcel;
    },
    isExceldata(state: any) {
      return state.excelData;
    },

    isBankGuarantee(state: any) {
      return state.bankGuarantee;
    },
    isBudgetSections(state: any) {
      //assign row index
      // Check if budget_sections exists in state.projectBudgets
      if (state.projectBudgets?.budget_sections) {
        // Clone the budget_sections array to avoid modifying the original data
        const clonedBudgetSections = [...state.projectBudgets.budget_sections];

        // Assign row index to each element in the cloned array
        clonedBudgetSections.forEach((section) => {
          section?.budget_items?.forEach(
            (item: BudgetLineItem, index: { index: number | string }) => {
              item.row_index = index;
            }
          );
        });

        return clonedBudgetSections;
      }

      return state.projectBudgets?.budget_sections;
    },
    isTotalStatistics(state: any) {
      return state.projectBudgets?.total_statistics;
    },
    isSplitted(state: any) {
      return state.projectBudgets?.is_splited;
    },
    canSplit(state: any) {
      return state.projectBudgets?.can_splite;
    },
    isBudgetSectionsID(state: any) {
      return state.projectBudgetSetionId;
    },
    isBudgetData(state: any) {
      return state.budgetData;
    },
    isInitialBudgetData(state: any) {
      return state.initialBudgetData;
    },
    isBudgetLineItems(state: any) {
      return state.budgetLineItems;
    },
    isCurrentYear(state: any) {
      return state.currentYear;
    },
    isEditSplit(state: any) {
      return state.saveSplit;
    },
    isSplitEditMode(state: any) {
      return state.splitEditMode;
    },
    isCreatedSplit(state: any) {
      return state.createdSplit;
    },
    isMileStoneData(state: any) {
      return state.mileStoneData;
    },
    isSplitType(state: any) {
      return state.splitType;
    },
    isTotalPages(state: any) {
      return state.totalPages;
    },
    isCurrentPages(state: any) {
      return state.curentPage;
    },
    isEmptyBudgetState(state: any) {
      if (
        state.projectBudgets?.budget_sections?.length === 0 ||
        state.projectBudgets?.bop_data?.length === 0 ||
        state.projectBudgets?.total_statistics === 0
      )
        return true;
    },
    getError(state: any) {
      return state.error;
    },
    getResponse(state: any) {
      return state.response;
    },
    getBudgetLineItemAfterRowRemove(state: any) {
      return state.budgetLineItemAfterRowRemove;
    },
    getIncomeCostPair(state: any) {
      return state.incomeCostPair;
    },
    getBackstopping(state: any) {
      return state.backstopping;
    },
    getYearlyCostSubtotals(state: any) {
      return state.yearlyCostSubtotals;
    },
    getYearlyInternalCostSubtotals(state: any) {
      return state.yearlyInternalCostSubtotals;
    },
    getYearlyIncomeSubtotals(state: any) {
      return state.yearlyIncomeSubtotals;
    },
    getAllCostSubtotals(state: any) {
      return state.allCostSubtotals;
    },
    getAllIncomeSubtotals(state: any) {
      return state.allIncomeSubtotals;
    },
    getAllInternalCostSubtotals(state: any) {
      return state.allInternalCostSubtotals;
    },
    getShowLocalBudget(state: any) {
      return state.projectBudgets.show_local_budget;
    },
    isSavedBudgetVersion(state: any) {
      return state.savedBudgetVersion;
    },
    getAllPostBudgetCalculation(state: any) {
      return state.allPostBudgetCalculation;
    },
    getBasicVersion(state: any) {
      return state.basic_version;
    },
    getAllBudgetData(state: any) {
      return state.allBudgetData;
    },
    getTotalIncome(state: any){
      return state.budgetData?.reduce((sum:any, section:any) => sum + section.total_income, 0)
    }
  },
  actions: {
    createUpdateBudgetLineItem(context: ActionContext<any, any>) {
      // const projectID = context.state.projectID
      const budgetData = context?.state?.budgetData;
      const payload = {
        budget_data: budgetData,
      };

      Api()
        .post(`${addOrUpdateBudgetItem}`, payload)
        .then((response: any) => {
          if (response.data.status === "success") {
            // context.dispatch('getAllProjectBudgets', projectID)
          }
        });
    },
    deleteBudgetLineItem(context: ActionContext<any, any>, payload: any) {
      // const projectID = context.state.projectID;
      Api()
        .delete(`${deleteBudgetItem}${payload}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            // context.dispatch("getAllProjectBudgets", projectID);
          }
        });
    },

    async createBudgetSection(
      { commit }: ActionContext<any, any>,
      payload: BudgetSectionPayload
    ) {
      try {
        const data = await Api().post(postBudgetSectionUrl, {
          project: payload.project,
          title: payload.title,
        });
        commit("SET_RESPONSE", data.data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },

    async updateBudgetSection(
      { commit }: ActionContext<any, any>,
      payload: BudgetSectionPayload
    ) {
      try {
        const data = await Api().patch(
          `${patchBudgetSectionUrl}${payload.budgetSectionId}/`,
          {
            title: payload.title,
          }
        );
        commit("SET_RESPONSE", data.data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },
    async deleteBudgetSection(
      { state, commit }: ActionContext<any, any>,
      payload: BudgetSectionPayload
    ) {
      try {
        const data = await Api().delete(
          `${deleteBudgetSectionUrl}${payload.budgetSectionId}`
        );

        commit("SET_RESPONSE", data.data);
        if (state.response?.status === "success") {
          commit("REMOVE_BUDGET_SECTION", payload.budgetSectionId); //remove from store
        }
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },

    async updateBudgetSectionLineItems(
      { commit }: ActionContext<any, any>,
      payload: any
    ) {
      try {
        const data = await Api().patch(
          `${updateBudgetSectionLineItemUrl}${payload.project}/`,
          payload.request //array of sections with line items
        );

        commit("SET_RESPONSE", data.data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },
    async saveBudgetSectionLineItems(
      { commit }: ActionContext<any, any>,
      payload: any
    ) {
      try {
        const data = await Api().post(
          `${budgetBasicVersionSave}${payload.project}/`,
          payload.request //array of sections with line items
        );

        commit("SET_RESPONSE", data.data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },
    async updateBudgetBasicVersion(
      { commit }: ActionContext<any, any>,
      payload: any
    ) {
      try {
        const data = await Api().patch(
          `${updateBudgetBasicVersion}${payload.project}/`,
          payload.request //array of sections with line items
        );

        commit("SET_RESPONSE", data.data);
        commit("SET_ERROR", {});
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_RESPONSE", {});
      }
    },

    async fetchAllBudgetSection(
      { commit }: ActionContext<any, any>,
      payload: any
    ) {
      try {
        if (payload.load_page) {
          commit("SET_APP_LOADING_TO_STATE", true);
        }
        const data = await Api().get(
          `${getAllBudgetSectionUrl}${payload.project}/`
        );

        const responseData = data?.data?.data;
        // console.log("responseData", responseData.saved_basic_view_version);
        const budgetSections = responseData.budget_sections?.map(
          (budgetSection: any) => {
            if (
              budgetSection.title === "Mandatory" &&
              budgetSection.budget_items?.length === 0
            ) {
              budgetSection.budget_items.push({
                position: "Backstopping",
                structure: "Costs",
                account: "4901 Other costs",
                number_of_units: 0,
                amount_per_unit: 275,
                is_planning: false,
                total_amount: 0,
              });
            }
            return budgetSection;
          }
        );

        const payloadBudgetData = responseData?.budget_sections?.map(
          (budgetSection: any) => {
            return {
              budget_section_id: budgetSection.id,
              total_income: budgetSection.total_income,
              total_cost: budgetSection.total_cost,
              profit_margin: budgetSection.profit_margin,
              profit_margin_percentage: budgetSection.profit_margin_percentage,
              budget_items: budgetSection.budget_items.map((newItem: any) => {
                return newItem?.id
                  ? {
                      budget_item_id: newItem?.id,
                      structure: newItem?.structure,
                      position: newItem?.position,
                      number_of_units: newItem?.number_of_units,
                      amount_per_unit: newItem?.amount_per_unit,
                      account: newItem?.account,
                      is_planning: newItem?.is_planning,
                    }
                  : budgetSection.budget_items;
              }),
            };
          }
        );
        commit("SET_ALL_BUDGET_DATA", responseData);
        commit(
          "SET_SAVED_BUDGET_VERSION",
          responseData.saved_basic_view_version
        );
        commit("SET_BUDGET_DATA_TO_STATE", payloadBudgetData);
        commit("SET_INITIAL_BUDGET_DATA_TO_STATE", payloadBudgetData);
        commit("SET_ALL_PROJECT_BUDGETS_TO_STATE", {
          ...data.data.data,
          budget_sections: budgetSections,
        });
        commit("SET_ERROR", {});
        commit("SET_APP_LOADING_TO_STATE", false);
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_ALL_PROJECT_BUDGETS_TO_STATE", {});
        commit("SET_APP_LOADING_TO_STATE", false);
      }
    },

    async fetchPostBudgetCalculation(
      { commit }: ActionContext<any, any>,
      payload: any
    ) {
      // if(payload.load_page){
      //   commit("SET_APP_LOADING_TO_STATE", true);
      // }
      try {
        const data = await Api().get(
          `${getPostBudgetCalculation}${payload.project_id}/`
        );

        commit("SET_APP_LOADING_TO_STATE", false);
        commit("SET_ALL_POST_BUDGET_CALCULATION", data.data.data);
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_ALL_POST_BUDGET_CALCULATION", {});
      }
    },

    async fetchBudgetBasicVersion(
      { commit }: ActionContext<any, any>,
      payload: any
    ) {
      try {
        if (payload.load_page) {
          commit("SET_APP_LOADING_TO_STATE", true);
        }
        const data = await Api().get(
          `${getBudgetBasicVersion}${payload.project_id}/`
        );
        const responseData = data?.data?.data;

        const payloadBudgetData = responseData?.budget_sections?.map(
          (budgetSection: any) => {
            return {
              budget_section_id: budgetSection.id,
              total_income: budgetSection.total_income,
              total_cost: budgetSection.total_cost,
              profit_margin: budgetSection.profit_margin,
              profit_margin_percentage: budgetSection.profit_margin_percentage,
              budget_items: budgetSection.budget_items?.map((newItem: any) => {
                return newItem?.id
                  ? {
                      budget_item_id: newItem?.id,
                      structure: newItem?.structure,
                      position: newItem?.position,
                      number_of_units: newItem?.number_of_units,
                      amount_per_unit: newItem?.amount_per_unit,
                      account: newItem?.account,
                      is_planning: newItem?.is_planning,
                      total_amount: newItem?.total_amount,
                      cost: newItem?.cost,
                      income: newItem?.income,
                      profit_margin: newItem?.profit_margin,
                      profit_margin_percentage:
                        newItem?.profit_margin_percentage,
                    }
                  : budgetSection.budget_items;
              }),
            };
          }
        );
        commit(
          "SET_SAVED_BUDGET_VERSION",
          responseData.saved_basic_view_version
        );
        commit("SET_BUDGET_DATA_TO_STATE", payloadBudgetData);
        commit("SET_INITIAL_BUDGET_DATA_TO_STATE", payloadBudgetData);
        commit("SET_ALL_PROJECT_BUDGETS_TO_STATE", {
          ...data.data.data,
          budget_sections: responseData.budget_sections,
        });
        commit("SET_ERROR", {});
        commit("SET_APP_LOADING_TO_STATE", false);
      } catch ({ response }: any) {
        commit("SET_ERROR", response);
        commit("SET_APP_LOADING_TO_STATE", false);
      }
    },
  },
};
